/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { React, useCallback, useEffect, useMemo, useState } from 'react';
import FormContainer from '../CustomElements/FormContainer';
import CustomContainer from '../CustomElements/CustomContainer';
import CustomTitle from '../CustomElements/CustomTitle';
import Input from '../CustomElements/Input';
import CustomButton from '../CustomElements/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { updateInfocardFromServer, addInfocardFromServer } from '../../redux/actions/infocards.action';
import { addDepartmenOptions } from '../../redux/actions/options.action';
import { useInfocard, useLoader, useMaskedInput, useSelectEdit } from './Common';
import { useLocation } from 'react-router-dom';
import useOptionsLoader from '../../customHook/useOptionsLoader';
import { load, remove } from '../../redux/actions/loader.action.js';
import { initDepartmenOptionsFromServer, initOptionsDirectoratesFromServer } from '../../redux/actions/options.action';
import { handleUniversalChange } from '../../utils/formUtils.js';
import Selector from '../CustomElements/Selector.jsx';

const defVal = {
  kit_id: '',
  directorate: '',
  station: '',
  max_speed: '',
  road: '',
  min_dist: '',
  department: '',
  alert_dist: '',
  direction: '',
  // is_station: '',
};

export default function InfocardCreationOrEditing({ closeModal }) {
  // Состояние для формы
  const infocard = useInfocard();
  let loc = useLocation();
  const isEditing = useMemo(() => loc.pathname?.includes('infocards/edit'), [loc.pathname]);
  const [formData, setFormData] = useState(infocard || defVal);
  const [formErrors, setFormErrors] = useState(defVal);
  const dispatch = useDispatch();
  const optionsForSelect = useSelector((state) => state.options);
  const { isLoading, isSuccess } = useLoader();

  useEffect(() => {
    setFormData({ ...(infocard || {}) });
  }, [infocard]);

  //хук для запроса опций для дирекций и контроля селекта
  const messageForSelectDirectorates = 'Выберите дорогу';
  const directoratesControl = useOptionsLoader(
    formData,
    setFormData,
    ['directorate', 'department'],
    'road',
    initOptionsDirectoratesFromServer,
    messageForSelectDirectorates
  );

  //хук для запроса опций для подразделений и контроля селекта
  const messageForSelectDepartments = 'Выберите дирекцию';
  const departmentsControl = useOptionsLoader(
    formData,
    setFormData,
    ['department'],
    'directorate',
    initDepartmenOptionsFromServer,
    messageForSelectDepartments
  );

  const directionOptions = [
    { label: 0, value: '0' },
    { label: 1, value: '1' },
  ];

  const typeOptions = [
    { label: 'Станция', value: true },
    { label: 'Перегон', value: false },
  ];
  // Подразделения зависят от дороги
  useEffect(() => {
    if (formData.road) {
      // очищаем старые данные
      dispatch(addDepartmenOptions([]));
    }
  }, [formData.road]);

  const canApply = useMemo(() => {
    // Определяем список обязательных полей
    const requiredFields = Object.keys(defVal).filter((key) => !['max_speed', 'min_dist', 'alert_dist'].includes(key));

    // Проверка обязательных полей, включая явную проверку на undefined или null
    if (requiredFields.some((key) => formData[key] === undefined || formData[key] === null)) {
      return false;
    }

    // Проверка на ошибки валидации
    if (Object.values(formErrors).some((x) => !!x)) {
      // TODO: а может не втихую выходить, а выводить какие-то ошибки...
      return false;
    }

    // Все условия выполнены
    return true;
  }, [formData, formErrors, infocard]);

  const onApply = useCallback(() => {
    dispatch(load());
    if (isEditing) {
      dispatch(updateInfocardFromServer(formData));
    } else {
      dispatch(addInfocardFromServer(formData));
    }
  }, [dispatch, formData, isEditing, infocard]);

  function useDefaultValues(key) {
    return {
      name: key,
      state: formData[key],
      error: formErrors[key],
      changeState: (_state, _error) => {
        setFormErrors((prev) => ({
          ...prev,
          [key]: _error,
        }));
        if (!_error) {
          setFormData((prev) => ({
            ...prev,
            [key]: _state,
          }));
        }
      },
      width: '100%',
      height: 'var(--sadr-font-size-120)',
    };
  }

  let _editControls = [
    <Input
      placeholder="Введите ID"
      onChange={(event) =>
        setFormData((prev) => ({
          ...prev,
          kit_id: event.target.value,
        }))
      }
      name="kit_id"
      label="КИТ ID *"
      width="100%"
      height="var(--sadr-font-size-60)"
      value={formData?.kit_id || ''}
      isCoordinate={true}
      isAllowed={undefined}
    />,
    <Selector
      options={typeOptions}
      onChange={(selectedOption) => {
        handleUniversalChange(formData, setFormData, null, 'is_station', selectedOption, 'select');
      }}
      placeholder="Выберите тип"
      value={typeOptions.find((option) => option.value === formData.is_station) || null}
      name="is_station"
      isMulti={false}
      label="Тип *"
    />,

    useSelectEdit({
      ...useDefaultValues('road'),
      placeholder: 'Выберите дорогу',
      label: 'Дорога *',
      options: optionsForSelect?.roads,
    }),
    <Selector
      options={directionOptions}
      onChange={(selectedOption) => {
        handleUniversalChange(formData, setFormData, null, 'direction', selectedOption, 'select');
      }}
      placeholder="Выберите направление"
      value={
        formData.direction || formData?.direction === 0
          ? directionOptions.find((option) => option.value === formData.direction)
          : null
      }
      name="direction"
      isMulti={false}
      label="Направление *"
    />,

    useSelectEdit({
      ...useDefaultValues('directorate'),
      placeholder: 'Выберите дирекцию',
      label: 'Дирекция *',
      options: optionsForSelect?.directorates,
      isDisabled: directoratesControl?.isSelectorDisabled,
      message: directoratesControl?.message,
      customClick: directoratesControl?.onSelectorChange,
      bottom: '-15px',
    }),
    useMaskedInput({
      ...useDefaultValues('max_speed'),
      placeholder: 'Введите максимальную скорость в км/ч',
      label: 'Разрешенная макс. скорость движения',
      valueToStr: (v) => v,
      getStateFromChange: (arr) => {
        const str = arr[0],
          value = +str;
        let err = null;
        if (!str || !Number.isFinite(value) || value < 1) err = 'Введите скорость в км/ч';
        return { err, value };
      },
      mask: '999 км/ч',
    }),

    useSelectEdit({
      ...useDefaultValues('department'),
      placeholder: 'Выберите подразделение',
      label: 'Подразделение *',
      options: optionsForSelect?.departments,
      isDisabled: departmentsControl?.isSelectorDisabled,
      message: departmentsControl?.message,
      customClick: departmentsControl?.onSelectorChange,
      bottom: '-15px',
    }),
    useMaskedInput({
      ...useDefaultValues('min_dist'),
      placeholder: 'Введите мин. расстояние оповещения в метрах',
      label: 'Минимальное расстояние оповещения',
      mask: '99999 м.',
      valueToStr: (v) => v,
      getStateFromChange: (arr) => {
        const str = arr[0],
          value = +str;
        let err = null;
        if (!str || !Number.isFinite(value) || value < 1) err = 'Введите расстояние в метрах';
        return { err, value };
      },
    }),

    <Input
      placeholder="Введите станцию"
      onChange={(event) =>
        setFormData((prev) => ({
          ...prev,
          [isEditing ? 'name' : 'station']: event.target.value,
        }))
      }
      name={isEditing ? 'name' : 'station'}
      label="Станция/перегон *"
      width="100%"
      height="var(--sadr-font-size-60)"
      value={isEditing ? formData?.name : formData?.station}
      selfAlign="end"
    />,
    useMaskedInput({
      ...useDefaultValues('alert_dist'),
      placeholder: 'Введите расстояние тревоги в метрах',
      label: 'Тревога при приближ. ПС с исправным УИППС',
      mask: '9999 м.',
      valueToStr: (v) => v,
      getStateFromChange: (arr) => {
        const str = arr[0],
          value = +str;
        let err = null;
        if (!str || !Number.isFinite(value) || value < 1) err = 'Введите расстояние в метрах';
        return { err, value };
      },
    }),
  ];

  const editControls = _editControls.map((control, index) => (
    <div
      key={index}
      column={index % 2 == 0 ? 'left' : 'right'}
      selfAlign={index === 8 ? 'start' : ''}
      className="infocard__column"
    >
      {control}
    </div>
  ));

  return (
    <CustomContainer>
      <CustomTitle
        titleText={isEditing ? 'РЕДАКТИРОВАНИЕ ИНФОРМАЦИОННОЙ КАРТЫ' : 'СОЗДАНИЕ НОВОЙ ИНФОРМАЦИОННОЙ КАРТЫ'}
      />
      {(!isLoading || isLoading) && !isSuccess && (
        <CustomContainer>
          <FormContainer className="form-container_form w-100">
            {editControls}
            <CustomButton
              width="var(--sadr-font-size-420)"
              height="var(--sadr-font-size-60)"
              text={isEditing ? 'Изменить информационную карту' : 'Добавить информационную карту'}
              onClick={onApply}
              disabled={!canApply}
              isButton
            />
          </FormContainer>
        </CustomContainer>
        // <CustomContainer>
        //   <FormContainer>
        //     <Input
        //       column="left"
        //       placeholder="Введите ID"
        //       onChange={(event) =>
        //         setFormData((prev) => ({
        //           ...prev,
        //           kit_id: event.target.value,
        //         }))
        //       }
        //       name="kit_id"
        //       label="КИТ ID *"
        //       width="100%"
        //       height="var(--sadr-font-size-60)"
        //       value={formData?.kit_id || ''}
        //       isCoordinate={true}
        //       isAllowed={undefined}
        //     />
        //     <Selector
        //       column="left"
        //       options={optionsForSelect.roads}
        //       onChange={(selectedOption) => {
        //         handleUniversalChange(formData, setFormData, null, 'road', selectedOption, 'select');
        //       }}
        //       placeholder={'Выберите дорогу'}
        //       isMulti={false}
        //       label={'Дорога'}
        //       value={
        //         formData.road ? optionsForSelect?.roads?.find((option) => option.value === formData.road) : null
        //       }
        //       name="road"
        //     />
        //     <Selector
        //       column="left"
        //       options={optionsForSelect?.directorates}
        //       onChange={(selectedOption) => {
        //         handleUniversalChange(formData, setFormData, null, 'directorate', selectedOption, 'select');
        //       }}
        //       placeholder={'Выберите дирекцию'}
        //       label={'Дирекция'}
        //       value={
        //         formData.directorate
        //           ? optionsForSelect?.directorates?.find((option) => option.value === formData.directorate)
        //           : null
        //       }
        //       isMulti={false}
        //       name="directorate"
        //       isDisabled={directoratesControl?.isSelectorDisabled}
        //       message={directoratesControl?.message}
        //       customClick={directoratesControl?.onSelectorChange}
        //     />
        //     <Selector
        //       column="left"
        //       options={optionsForSelect.departments}
        //       onChange={(selectedOption) => {
        //         handleUniversalChange(formData, setFormData, null, 'department', selectedOption, 'select');
        //       }}
        //       placeholder={'Выберите № подразделения'}
        //       isMulti={false}
        //       label={'Подразделение'}
        //       value={
        //         formData.department
        //           ? optionsForSelect?.departments?.find((option) => option.value === formData.department)
        //           : null
        //       }
        //       name="department"
        //       isDisabled={departmentsControl?.isSelectorDisabled}
        //       message={departmentsControl?.message}
        //       customClick={departmentsControl?.onSelectorChange}
        //     />

        //     <Selector
        //       column="left"
        //       options={directionOptions}
        //       onChange={(selectedOption) => {
        //         handleUniversalChange(formData, setFormData, null, 'direction', selectedOption, 'select');
        //       }}
        //       placeholder="Выберите направление"
        //       value={
        //         formData.direction || formData?.direction === 0
        //           ? directionOptions.find((option) => option.value === formData.direction)
        //           : null
        //       }
        //       name="direction"
        //       isMulti={false}
        //       label="Направление *"
        //     />
        //     <Input
        //       column="right"
        //       placeholder="Введите станцию"
        //       onChange={(event) =>
        //         setFormData((prev) => ({
        //           ...prev,
        //           [isEditing ? 'name' : 'station']: event.target.value,
        //         }))
        //       }
        //       name={isEditing ? 'name' : 'station'}
        //       label="Станция/перегон *"
        //       width="100%"
        //       height="var(--sadr-font-size-60)"
        //       value={isEditing ? formData?.name : formData?.station}
        //     />
        //     <Input
        //       column="right"
        //       placeholder="Введите максимальную скорость в км/ч"
        //       label="Разрешенная макс. скорость движения*"
        //       value={formData?.max_speed || ''}
        //       onChange={(event) => handleUniversalChange(formData, setFormData, event)}
        //       mask="999 км/ч."
        //       maskText='км/ч.'
        //       maskChar={null}
        //       name="max_speed"
        //     />

        //     <Input
        //       column="right"
        //       placeholder="Введите мин. расстояние оповещения в метрах"
        //       label="Минимальное расстояние оповещения *"
        //       value={formData?.min_dist || ''}
        //       onChange={(event) => handleUniversalChange(formData, setFormData, event)}
        //       mask="99999 м."
        //       maxLength={5}
        //       name="min_dist"
        //     />

        //     <Input
        //       column="right"
        //       placeholder="Введите расстояние тревоги в метрах"
        //       label="Тревога при приближ. ПС с исправным УИППС *"
        //       value={formData?.alert_dist || ''}
        //       onChange={(event) => handleUniversalChange(formData, setFormData, event)}
        //       mask="9999 м."
        //       maxLength={4}
        //       name="alert_dist"
        //     />

        //     <CustomButton
        //       width="var(--sadr-font-size-420)"
        //       height="var(--sadr-font-size-60)"
        //       text={isEditing ? 'Изменить информационную карту' : 'Добавить информационную карту'}
        //       onClick={onApply}
        //       disabled={!canApply}
        //       isButton
        //     />
        //   </FormContainer>
        // </CustomContainer>
      )}
      {!isLoading && isSuccess && (
        <div className="d-flex flex-column flex-nowrap mx-auto my-auto">
          <span className="label-success">
            {isEditing ? 'Информационная карта изменена' : 'Новая информационная добавлена'}
          </span>
          <CustomButton
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            text={isEditing ? 'К информационной карте' : 'К информационным картам'}
            onClick={() => {
              closeModal(true);
              dispatch(remove());
            }}
            isButton
          />
        </div>
      )}
    </CustomContainer>
  );
}
