/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useTable, useSortBy, useFilters, useGlobalFilter, useRowState } from 'react-table';
import '../../styles/Table.css';
import HeaderForObject from '../CustomElements/HeaderForObject';
import { useDispatch, useSelector } from 'react-redux';
import translations from '../../content/Error/error.json';
import useTranslations from '../../customHook/useTranslations';
import { updateSortData } from '../../redux/actions/sortData.action';

function Table({
  data,
  columns,
  searchInput,
  open,
  hideHeader,
  showGlobalSearch,
  viewHeader,
  getRowProps,
  radius,
  colorTR,
  text,
  isEditing,
  changes,
  setChanges,
  hiddenDiv = false,
  highlightRowIndex,
  getRowId,
  className,
  fixFirstColumn,
  bottomBoundaryRef,
  arrLength,
  disableInternalSorting = false, // Отключить внутреннюю сортировку, если true
}) {
  // получение словаря для статических данных в зависимости от выбраного языка
  const t = useTranslations(translations);
  const dispatch = useDispatch();
  const [filterInput, setFilterInput] = useState(''); // Состояние для хранения значения глобального фильтра
  const [selectedRow, setSelectedRow] = useState(null); // Состояние для индекса выбранной строки
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter, state } = useTable(
    {
      getRowId,
      columns,
      data: data ? data : [],
      setChanges,
      // Не сбрасываем состояние при изменении источника данных
      autoResetRowState: false,
      autoResetSortBy: false,
    },
    useFilters, // Используем хук для фильтрации
    useGlobalFilter, // Хук для глобальной фильтрации
    useSortBy, // Используем хук для сортировки
    useRowState // состояния строк (нужно для редактирования)
  );

  // Используем глобальное состояние из Redux
  const sortData = useSelector((state) => state?.sortData);

  // Функция для обработки кликов по заголовкам таблицы
  const handleSortClick = (columnId) => {
    const newDirection =
      sortData.sort === columnId
        ? sortData.direction === 'asc'
          ? 'desc'
          : sortData.direction === 'desc'
          ? null
          : 'asc'
        : 'asc';
    if (disableInternalSorting) {
      // Обновляем данные сортировки в Redux
      dispatch(updateSortData({ sort: columnId, direction: newDirection }));
    }
  };

  // Функция для обработки клика по ячейке
  const handleCellClick = (index, value, row) => {
    if (open) {
      setSelectedRow(index); // Устанавливаем выбранную строку
      open(value, row); // Вызываем функцию open с значением ячейки
    }
  };

  // Эффект для применения глобального фильтра
  const { globalFilter } = state;
  useEffect(() => {
    const actualFilterValue = showGlobalSearch ? filterInput : searchInput;
    // Применяем сохраненное значение фильтра
    setGlobalFilter(actualFilterValue);
  }, [filterInput, data, setGlobalFilter, searchInput, showGlobalSearch]);

  useEffect(() => {
    const actualFilterValue = showGlobalSearch ? filterInput : searchInput;
    if (actualFilterValue && !globalFilter) {
      setGlobalFilter(actualFilterValue);
    }
  });

  // Ref для хранения ссылок на все рендеренные строки
  const rowRefs = useRef([]);

  useEffect(() => {
    // Находим индекс подсвеченной строки
    const highlightedRowIndex = rows.findIndex((row) => Number(row.id) === highlightRowIndex);
    // Если строка найдена и ref существует, прокручиваем к ней
    if (highlightedRowIndex >= 0 && rowRefs.current[highlightedRowIndex]) {
      rowRefs.current[highlightedRowIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [highlightRowIndex, rows]);
  return (
    <>
      {showGlobalSearch && (
        <HeaderForObject
          searchInput={filterInput}
          onFilterChange={(e) => {
            setFilterInput(e?.target?.value);
          }}
          setGlobalFilter={setGlobalFilter}
        />
      )}
      {!hiddenDiv && (
        <div
          id="table-fixer"
          style={{
            minHeight: 'var(--sadr-font-size-20)',
            backgroundColor: 'var(--sadr-background-secondary)',
            borderTopLeftRadius: 'var(--sadr-font-size-12)',
            borderTopRightRadius: 'var(--sadr-font-size-12)',
          }}
        ></div>
      )}
      {viewHeader && <h3 className="table_title">{viewHeader}</h3>}
      <div className="table_container">
        <table
          {...getTableProps()}
          className={`custom-table ${className ? className : ''} ${
            bottomBoundaryRef && arrLength > 20 ? 'table__hiden-boundary' : ''
          }`}
        >
          {!hideHeader && ( // Условие для отображения шапки
            <thead>
              {headerGroups.map((headerGroup) => {
                return (
                  <tr {...headerGroup.getHeaderGroupProps()} className="table_header">
                    {headerGroup.headers.map((column, columnIndex) => {
                      // Если внутренняя сортировка отключена, перехватываем обработчик клика
                      const isSortedColumn = sortData.sort === column.id;
                      const sortIndicator = isSortedColumn
                        ? sortData.direction === 'asc'
                          ? ' ↑'
                          : sortData.direction === 'desc'
                          ? ' ↓'
                          : ''
                        : '';
                      // Проверяем, отключена ли сортировка для столбца
                      const isSortingDisabled = column.disableSorting || column.tooltipText;
                      const thProps = isSortingDisabled
                        ? {
                            ...column.getHeaderProps(),
                            onClick: undefined, // Убираем клики
                            title: column.tooltipText, // Добавляем тултип
                          }
                        : disableInternalSorting
                        ? {
                            ...column.getHeaderProps(),
                            onClick: () => handleSortClick(column.id), // Кастомная сортировка
                          }
                        : {
                            ...column.getHeaderProps(column.getSortByToggleProps()), // Внутренняя сортировка
                          };

                      return (
                        <th
                          {...thProps}
                          className={` ${fixFirstColumn && columnIndex === 0 ? 'sticky-first-header' : ''} 
                          ${isSortingDisabled ? 'no-pointer' : 'sortable'}`}
                        >
                          <div className="header-content">
                            {column?.render('Header')}
                            <span>
                              {!isSortingDisabled &&
                                (disableInternalSorting
                                  ? sortIndicator
                                  : column?.isSorted
                                  ? column?.isSortedDesc
                                    ? ' ↓'
                                    : ' ↑'
                                  : '')}
                            </span>
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
          )}
          <tbody {...getTableBodyProps()}>
            {rows?.length > 0 ? (
              rows?.map((row, rowIndex) => {
                prepareRow(row);
                // Проверяем, что is_on строго равно false
                const rowClass = row.original.is_disabled === true ? 'row-highlight' : '';
                // Первичная проверка на подсветку строки по индексу
                const isHighlightRow = rowIndex === highlightRowIndex;
                // Условие стиля на основе данных с учетом isHighlightRow
                const rowStyle = isHighlightRow
                  ? { backgroundColor: '#c1ffcf' } // Цвет подсветки выбранной строки
                  : colorTR
                  ? row.original.alertType === 5 || row.original.alertType === 2
                    ? { backgroundColor: '#EE9D9D' } // Красный фон для alertType 5 или 2
                    : { backgroundColor: '#F2DB89' } // Желтый фон для других значений alertType
                  : {}; // Без стиля, если colorTR ложно
                // Проверяем, выбрана ли данная строка
                const isRowSelected = rowIndex === selectedRow; // Проверяем, выбрана ли данная строка
                return (
                  <tr
                    {...row.getRowProps()}
                    key={row.id}
                    ref={(el) => (rowRefs.current[rowIndex] = el)}
                    style={rowStyle} // Применение стиля к строке
                    className={`table_row ${rowClass} ${isRowSelected ? 'selected-tr' : ''}`}
                    onClick={() => handleCellClick(rowIndex, row.cells[0].value, row?.original)} // Добавляем обработчик клика
                  >
                    {row.cells.map((cell, cellIndex) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={`${className ? className : ''} ${
                            fixFirstColumn && cellIndex === 0 ? 'sticky-first-column' : ''
                          }`}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              }) // Рендер строки с сообщением об отсутствии данных
            ) : (
              <tr>
                <td colSpan={columns.length} className="table-no-data">
                  {text ? text : t?.noData}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* Если есть возможность подгрузить данные (bottomBoundaryRef) то рисуется невидимый элемент, который попав в область видимости вызывает подгрузку новых данных */}
        {arrLength > 49 && <span className="bottom-boundary-ref" ref={bottomBoundaryRef} />}
        <div className="table-gradient"></div>
      </div>
    </>
  );
}

export default Table;
