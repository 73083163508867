import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import CustomContainer from '../CustomElements/CustomContainer';
import FormContainer from '../CustomElements/FormContainer';
import CustomButton from '../CustomElements/CustomButton';
import Selector from '../CustomElements/Selector';
import DateSelector from '../CustomElements/DateSelector';
import CustomTitle from '../CustomElements/CustomTitle';
import Modal from '../Modal/Modal';
import Preloader from '../CustomElements/Preloader';
import ConfirmationPage from '../CustomElements/ConfirmationPage';

import { useButtonValidation } from '../../customHook/useButtonValidation';
import { handleUniversalChange } from '../../utils/formUtils';

import { getReportBrigadeFromServer, getDetailReportBrigadeFromServer } from '../../redux/actions/documentation.action';
import { initOptionsMrmData } from '../../redux/actions/options.action';
import { load, remove } from '../../redux/actions/loader.action';

function DownloadReport() {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // Получаем данные о процессе
  const loader = useSelector((state) => state.loader);

  // тайтл для модального окна
  const [titleForModal, setTitleForModal] = useState('');

  const [isOpen, setIsOpen] = useState(false);

  const closeCurrentModal = () => {
    //закрываем модальное окно
    setIsOpen(false);
    // сбрасываем тайтл
    setTitleForModal('');
    // очищаем стейт загрузки
    dispatch(remove());
  };

  // Получение данных о бригадах из Redux store.
  const { mrmIds } = useSelector((state) => state?.options);

  // состояние для формы
  const initialFormData = {
    date: '',
    mrmId: '',
  };
  const [formData, setFormData] = useState(initialFormData);

  // Функция для проверки валидности формы
  const isButtonDisabled = useButtonValidation(formData, ['mrmId']);

  const downloadReport = () => {
    const formattedDate = moment(formData.date).format('YYYY-MM-DD');
    // записываем тайтл в стейт
    setTitleForModal('ОТЧЕТ ПО РАБОТЕ БРИГАД');
    // Переводим в состояние загрузки
    dispatch(load());
    dispatch(getReportBrigadeFromServer(formattedDate));
  };

  const downloadDetailReport = () => {
    const formattedDate = moment(formData?.date).format('YYYY-MM-DD');
    // записываем тайтл в стейт
    setTitleForModal('ОТЧЕТ ПО РАБОТЕ БРИГАД (ДЕТАЛИЗАЦИЯ)');
    // Переводим в состояние загрузки
    dispatch(load());
    dispatch(getDetailReportBrigadeFromServer({ date: formattedDate, mrmId: formData?.mrmId }));
  };

  useEffect(() => {
    dispatch(initOptionsMrmData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //отслеживаем выполнение запроса
  useEffect(() => {
    // Если нет ошибок, открываем модальное окно отчета
    if (!loader?.isLoading && loader?.isSuccess) {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader?.isLoading, loader?.isSuccess]);

  useEffect(() => {
    // при закрытии компонента чистим стейт загрузки
    return () => {
      dispatch(remove());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="reports-item">
      <CustomContainer>
        <CustomTitle titleText="ОТЧЕТ ПО РАБОТЕ БРИГАД" />
        <FormContainer>
          <DateSelector
            column="left"
            width = "100%"
            height="var(--sadr-font-size-60)"
            label="Дата *"
            selectedDate={formData.date}
            setSelectedDate={(date) => handleUniversalChange(formData, setFormData, null, 'date', date, 'date')}
            name="date"
            placeholder="Выберите дату"
            maxDate={new Date()}
            isDisabled={loader?.isLoading}
          />
          <Selector
            column="right"
            options={mrmIds}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'mrmId', selectedOption, 'select');
            }}
            placeholder="Выберите ИУР"
            label="Выберите ИУР"
            width = "100%"
            height="var(--sadr-font-size-60)"
            value={mrmIds?.find((option) => option.value === formData.mrmId)}
            isMulti={false}
            name="mrmId"
            isDisabled={loader?.isLoading}
          />
          <Preloader isLoading={loader?.isLoading} />
          <CustomButton
            width = "100%"
            height="var(--sadr-font-size-60)"
            text="Скачать отчет"
            disabled={isButtonDisabled || loader?.isLoading}
            isButton
            onClick={downloadReport}
          />
          <CustomButton
            width = "100%"
            height="var(--sadr-font-size-60)"
            text="Скачать детализацию"
            disabled={!formData.mrmId || isButtonDisabled || loader?.isLoading}
            isButton
            onClick={downloadDetailReport}
          />
        </FormContainer>
      </CustomContainer>

      {isOpen && (
        <Modal isOpen={isOpen} onClose={closeCurrentModal} width="700px" height="450px">
          <CustomContainer>
            <CustomTitle titleText={titleForModal} />
            <ConfirmationPage
              addNew="К форме запроса"
              confirmText="Отчет сформирован!"
              onConfirm={closeCurrentModal} // Функция для закрытия модального окна или отмены действия
            />
          </CustomContainer>
        </Modal>
      )}
    </div>
  );
}

export default DownloadReport;
