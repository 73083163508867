import { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/DeviceDeployment.css';
// Компоненты пользовательского интерфейса
import CustomButton from '../CustomElements/CustomButton';
import Selector from '../CustomElements/Selector';
import DateSelector from '../CustomElements/DateSelector';
import CustomTitle from '../CustomElements/CustomTitle';
import Input from '../CustomElements/Input';
import FormContainer from '../CustomElements/FormContainer';
import CustomContainer from '../CustomElements/CustomContainer';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
// Утилиты и хуки
import { convertToDate } from '../../utils/convertToDate';
import { useButtonValidation } from '../../customHook/useButtonValidation';
import { handleUniversalChange, createFormData } from '../../utils/formUtils';
import useFormDataChanged from '../../customHook/useFormDataChanged';
import useOptionsLoader from '../../customHook/useOptionsLoader';
import useFilesOptions from '../../customHook/useFilesOptions';
// Действия Redux
import { load } from '../../redux/actions/loader.action';
import { editOperationStatusFromServer } from '../../redux/actions/statuses.action';
import {
  initDepartmenOptionsFromServer,
  initOptionsDirectoratesUippsFromServer,
} from '../../redux/actions/options.action';
import useSuccessListener from '../../customHook/useSuccessListener';
import FileSelector from '../CustomElements/FileSelector';

// компонент  изменения статуса уиппс 'эксплуатация выдача'

function EditOperationSendUippsStatus({ currentStatus, currentDevice, closeModal, title, t }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  // получаем опции из стора
  const optionsForSelect = useSelector((state) => state.options);

  // Состояние для формы. Мемоизируем начальные данные формы
  const initialFormData = useMemo(
    () => ({
      id: currentStatus?.id,
      deviceId: currentStatus?.device_id,
      typeId: currentDevice?.type_id,
      date: convertToDate(currentStatus?.date),
      roadId: currentStatus?.road_id,
      acceptanceCert: currentStatus?.acceptance_cert,
      acceptanceCertName: currentStatus?.acceptance_cert_name,
      directorateId: currentStatus?.directorate_id,
      departmentId: currentStatus?.department_id,
      trainTypeId: currentStatus?.train_type_id,
      trainNumber: currentStatus?.train_number,
      responsibleId: currentStatus?.driver_id,
      responsibleContact: currentStatus?.responsible_contact,
      //
      placement: currentStatus?.placement,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStatus]
  );

  const [formData, setFormData] = useState(initialFormData);

  //хук для контрола выбора файла
  const acceptanceCertOptions = useFilesOptions(setFormData, 'acceptanceCertName', 'acceptanceCert');

  // Состояния для кнопки и уведомления об успешном добавлении
  const [addSuccess, setAddSuccess] = useState(false);

  // Состояние для массива обязяательных полей
  const [optionalFields] = useState(['responsibleId', 'responsibleContact', 'trainTypeId', 'trainNumber', 'placement']);

  // Функция для отмены подтверждения и закрытия модального окна
  const closeStatusEditModal = () => setAddSuccess(false);

  // Функция для открытия модального окна подтверждения
  const handlerOpenModal = () => setAddSuccess(true);

  //проверяем были ли изменения в форме
  const isFormEdit = useFormDataChanged(initialFormData, formData, true);

  /** Функция для проверки валидности формы */
  const isButtonDisabled = useButtonValidation(formData, optionalFields);

  // Обработчик клика
  const handlerSubmit = (event) => {
    event.preventDefault();
    handlerOpenModal();
  };

  // отправка данных на сервер при изменении статуса
  const handlerSubmitEdit = (event) => {
    event.preventDefault();
    //переводим в состояние загрузки
    dispatch(load());
    const payload = createFormData(formData);
    // Отправляем данные на сервер через Redux
    dispatch(editOperationStatusFromServer(payload, formData.deviceId, 'usage'));
  };

  //хук для запроса опций для дирекций и контроля селекта
  const messageForSelectDirectorates = t?.messageForSelectDirectorates || 'Выберите дорогу';
  const directoratesControl = useOptionsLoader(
    formData,
    setFormData,
    ['directorateId', 'departmentId'],
    'roadId',
    initOptionsDirectoratesUippsFromServer,
    messageForSelectDirectorates
  );
  //хук для запроса опций для подразделений и контроля селекта
  const messageForSelectDepartments = t?.messageForSelectDepartments || 'Выберите дирекцию';
  const departmentsControl = useOptionsLoader(
    formData,
    setFormData,
    ['departmentId'],
    'directorateId',
    initDepartmenOptionsFromServer,
    messageForSelectDepartments
  );

  //отслеживаем выполнение запроса
  useSuccessListener(setFormData, initialFormData, closeModal, closeStatusEditModal);

  return (
    <CustomContainer>
      <CustomTitle titleText={title} deviceType={currentDevice.type} deviceId={currentDevice.id} />
      {addSuccess && (
        <ConfirmationPage
          confirmText={t?.confirmEditUippsStatusText || 'Вы уверены, что хотите изменить данные статуса УИППС?'}
          confirmButtonText={t?.confirmButton || 'Изменить'}
          cancelButtonText={t?.cancelButton || 'Отменить'}
          onConfirm={handlerSubmitEdit} // Функция, которая должна быть вызвана при подтверждении
          onCancel={closeStatusEditModal} // Функция для закрытия модального окна или отмены действия
        />
      )}
      {!addSuccess && (
        <>
          <FormContainer>
            <DateSelector
              column="left"
              width="100%"
              height="var(--sadr-font-size-60)"
              label={t?.labelDeploymentDate || 'Дата выдачи в эксплуатацию *'}
              selectedDate={formData.date}
              setSelectedDate={(date) => handleUniversalChange(formData, setFormData, null, 'date', date, 'date')}
              name="date"
              placeholder={t?.placeholderChooseDate || 'Выберите дату выдачи'}
              minDate={convertToDate(currentDevice?.last_action_date)}
              maxDate={new Date()}
            />
            <FileSelector
              formData={formData}
              setFormData={setFormData}
              attachmentOptions={acceptanceCertOptions}
              column={'left'}
              rowspan={2}
              t={t}
              file={{
                field: 'acceptanceCert',
                name: 'acceptanceCertName',
                label: t?.labelAcceptanceCert || 'Акт приема-передачи *',
              }}
            />
            {!currentStatus?.placement && (
              <Input
                onChange={(event) => handleUniversalChange(formData, setFormData, event)}
                value={formData?.responsibleId || ''}
                name="responsibleId"
                column="left"
                width="100%"
                height="var(--sadr-font-size-60)"
                placeholder={t?.placeholderEmployeeNumber || 'Введите табельный номер'}
                label={t?.labelEmployeeNumber || 'Табельный № машиниста'}
              />
            )}
            {currentStatus?.placement && (
              <Selector
                onChange={(selectedOption) => {
                  handleUniversalChange(formData, setFormData, null, 'trainTypeId', selectedOption, 'select');
                }}
                value={
                  formData.trainTypeId
                    ? optionsForSelect?.trainTypes.find((option) => option.value === formData.trainTypeId)
                    : null
                }
                name="trainTypeId"
                column="left"
                options={optionsForSelect?.trainTypes}
                width="100%"
                height="var(--sadr-font-size-60)"
                placeholder={t?.placeholderTrainType || 'Выберите тип подвижного состава'}
                label={t?.labelTrainType || 'Тип подвижного состава'}
                isMulti={false}
              />
            )}
            <Selector
              column="right"
              options={optionsForSelect?.roads}
              onChange={(selectedOption) => {
                handleUniversalChange(formData, setFormData, null, 'roadId', selectedOption, 'select');
              }}
              value={
                formData.roadId ? optionsForSelect?.roads?.find((option) => option.value === formData.roadId) : null
              }
              name="roadId"
              label={`${t?.labelRoad} *` || 'Дорога *'}
              placeholder={t?.placeholderRoad || 'Выберите дорогу'}
              width="100%"
              height="var(--sadr-font-size-60)"
              isMulti={false}
            />
            <Selector
              column="right"
              options={optionsForSelect?.directoratesUipps}
              onChange={(selectedOption) => {
                handleUniversalChange(formData, setFormData, null, 'directorateId', selectedOption, 'select');
              }}
              value={
                formData.directorateId
                  ? optionsForSelect?.directoratesUipps?.find((option) => option.value === formData.directorateId)
                  : null
              }
              placeholder={t?.placeholderDirectorate || 'Выберите дирекцию'}
              label={`${t?.labelDirectorate} *` || 'Дирекция *'}
              width="100%"
              height="var(--sadr-font-size-60)"
              isMulti={false}
              name="directorateId"
              isDisabled={directoratesControl?.isSelectorDisabled}
              message={directoratesControl?.message}
              customClick={directoratesControl?.onSelectorChange}
            />
            <Selector
              column="right"
              options={optionsForSelect?.departments}
              onChange={(selectedOption) => {
                handleUniversalChange(formData, setFormData, null, 'departmentId', selectedOption, 'select');
              }}
              value={
                formData.departmentId
                  ? optionsForSelect?.departments?.find((option) => option.value === formData?.departmentId)
                  : null
              }
              placeholder={t?.placeholderSelectDepartment || 'Выберите подразделение'}
              label={`${t?.labelDepartment} *` || 'Подразделение *'}
              width="100%"
              height="var(--sadr-font-size-60)"
              isMulti={false}
              name="departmentId"
              isDisabled={departmentsControl?.isSelectorDisabled}
              message={departmentsControl?.message}
              customClick={departmentsControl?.onSelectorChange}
            />
            {!currentStatus?.placement && (
              <Input
                column="right"
                onChange={(event) => handleUniversalChange(formData, setFormData, event)}
                value={formData?.responsibleContact || ''}
                label={t?.labelDriverContact || 'Контакты машиниста'}
                placeholder={t?.placeholderDriverContact || 'Введите контакты'}
                width="100%"
                height="var(--sadr-font-size-60)"
                name="responsibleContact"
              />
            )}
            {currentStatus?.placement && (
              <Input
                column="right"
                onChange={(event) => handleUniversalChange(formData, setFormData, event)}
                value={formData?.trainNumber || ''}
                label={t?.labelTrainNumber || 'Номер подвижного состава'}
                placeholder={t?.placeholderTrainNumber || 'Введите № подвижного состава'}
                width="100%"
                height="var(--sadr-font-size-60)"
                name="trainNumber"
              />
            )}
          </FormContainer>
          <CustomButton
            onClick={(event) => {
              handlerSubmit(event);
            }}
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            text={t?.buttonSaveChanges || 'Сохранить изменения'}
            disabled={isButtonDisabled || !isFormEdit}
            isButton
          />
        </>
      )}
    </CustomContainer>
  );
}

export default EditOperationSendUippsStatus;
