import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import '../../styles/Infocards.css';
import Modal from '../Modal/Modal';
import FilterForInfocards from '../Filter/FilterForInfocards';
import HeaderForObject from '../CustomElements/HeaderForObject';
import Table from '../Table/Table';
import CustomButton from '../CustomElements/CustomButton';
import InfocardCreationOrEditing from './InfocardCreationOrEditing';
import InfocardView from './InfocardView';
import DownloadReferenceForm from './DownloadReferenceForm';

import useUrlNavigate from '../../customHook/useUrlNavigate';
import { useRole } from '../../customHook/useRole';
import { requestInfocards } from '../../redux/actions/infocards.action';
import { remove } from '../../redux/actions/loader.action';
import { defaultCellRender } from '../../utils/formatValueForHTML';
import { useLoader } from './Common';

// компонент для отображения страницы инфокарты

function Infocards(props) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // роли пользователя
  const isAdmin = useRole('admin');

  // хук для отслеживания процесса запросов
  const { isLoading, isSuccess, isFailed } = useLoader();

  //получаем все инфокарты из стейта
  const infocards = useSelector((state) => state.infocards.items);

  //стейт для инпута поиска
  const [filterInput, setFilterInput] = useState('');

  //хук для навигации
  const changeURL = useUrlNavigate();

  // стейт для параметров фильтрации
  const initialFormData = {
    road: '',
    directorate: '',
    region: '',
    department: '',
    station: '',
    version: '',
    is_disabled: '',
  };
  const [formData, setFormData] = useState(initialFormData);

  //открытие модального окна для создания инфокарты
  const navigateToCreateInfocard = () => {
    changeURL((u, rootpath) => {
      u.pathname = `${rootpath}/create`;
    });
  };

  //открытие модального окна для создания инфокарты
  const navigateToDownloadReference = () => {
    changeURL((u, rootpath) => {
      u.pathname = `${rootpath}/reference`;
    });
  };

  //закрытие модального окна подтверждения редактирования инфокарты
  const closeSuccessEditModal = () => {
    dispatch(remove());
    changeURL((u) => {
      u.pathname = u.pathname.replace('edit/', 'view/');
    });
  };

  // функция для перехода к инфокарте из таблицы
  const openInfocard = (_, { station }) => {
    if (!station) return;

    changeURL((url, basePath) => {
      url.pathname = `${basePath}/view/${station}`;
    });
  };

  // функция для закрытия модальных окон и навигации
  const closeModalHandler = useCallback(
    (toRoot = false) => {
      // Блокируем закрытие окна во время загрузки
      if (isLoading) return;

      // Убираем состояние загрузки, если операция успешна
      if (isSuccess) {
        dispatch(remove());
      }

      changeURL((url, rootPath) => {
        url.pathname = toRoot || isFailed ? rootPath : url.pathname.replace('edit/', 'view/');
      });
    },
    [changeURL, isLoading, isSuccess, isFailed, dispatch]
  );
  const closeModalToRoot = useCallback(() => closeModalHandler(true), [closeModalHandler]);

  const columns = useMemo(
    () => [
      {
        Header: 'Дорога',
        accessor: 'road_name',
        Cell: ({ value }) => (
          <div className="road-row">
            <div className="road-icon"></div>
            <div className="road-text">
              <span>{value}</span>
            </div>
          </div>
        ),
      },
      {
        Header: 'Подразделение',
        accessor: 'department_name',
        Cell: ({ value }) => <span className="text-nowrap">{defaultCellRender(value)}</span>,
      },
      {
        Header: 'Регион',
        accessor: 'region',
        Cell: ({ value }) => <span className="text-nowrap">{defaultCellRender(value)}</span>,
      },
      {
        Header: 'Станция/Перегон',
        accessor: 'name',
        Cell: ({ value }) => <span className="text-break">{defaultCellRender(value)}</span>,
      },
      {
        Header: 'Тип',
        accessor: 'is_station',
        Cell: ({ value }) => <span className="text-nowrap">{value ? 'Станция' : 'Перегон'}</span>,
      },
      {
        Header: 'Направление',
        accessor: 'direction',
        Cell: ({ value }) => <span className="text-break">{defaultCellRender(value)}</span>,
      },
      {
        Header: 'Версия',
        accessor: 'version',
        Cell: ({ value }) => <span className="text-break">{defaultCellRender(value)}</span>,
      },
      {
        Header: 'Версия КИТ',
        accessor: 'version_kit',
        Cell: ({ value }) => <span className="text-break">{defaultCellRender(value)}</span>,
      },
      {
        Header: 'Вкл',
        accessor: 'is_disabled',
        Cell: ({ value }) => <span className="text-break">{!value ? 'Да' : 'Нет'}</span>,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [infocards]
  );

  // Запрашиваю все инфокарты
  useEffect(() => {
    dispatch(requestInfocards(formData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <div className="infocard">
      <FilterForInfocards initialFormData={initialFormData} formData={formData} setFormData={setFormData} />
      <div id="infocard-container" className="d-flex flex-column flex-nowrap table-container w-100">
        <HeaderForObject
          searchInput={filterInput}
          onFilterChange={(e) => {
            setFilterInput(e?.target?.value);
          }}
          onFilterSubmit={() => {}}
        />
        <Table
          data={infocards}
          columns={columns}
          searchInput={filterInput}
          open={openInfocard}
          showGlobalSearch={false}
        />

        <div className={`infocards_btn-container ${!isAdmin ? 'padding-10' : ''}`}>
          {isAdmin && <CustomButton text="+ Создать информационную карту" onClick={navigateToCreateInfocard} />}
          {isAdmin && <CustomButton text="+ Загрузить справочник" onClick={navigateToDownloadReference} />}
        </div>
      </div>

      <Routes>
        <Route
          path="/edit/:station/*"
          element={
            <Modal isOpen onClose={closeSuccessEditModal} containerSelector="#infocard-container">
              <InfocardCreationOrEditing closeModal={closeSuccessEditModal} />
            </Modal>
          }
        />
        <Route
          path="/create/*"
          element={
            <Modal isOpen onClose={closeModalToRoot} containerSelector="#infocard-container">
              <InfocardCreationOrEditing closeModal={closeModalToRoot} />
            </Modal>
          }
        />
        <Route
          path="/view/:station/*"
          element={
            <Modal width="100%" isOpen onClose={closeModalToRoot}>
              <InfocardView closeModal={closeModalToRoot} />
            </Modal>
          }
        />

        <Route
          path="/reference"
          element={
            <Modal containerSelector="#infocard-container" isOpen onClose={closeModalToRoot}>
              <DownloadReferenceForm closeModal={closeModalToRoot} formData={formData} />
            </Modal>
          }
        />
      </Routes>
    </div>
  );
}

export default Infocards;
