import { FETCH_DATA_REQUEST, SELECT_PARAMETER, SET_SELECTED_MAILING } from '../type';

const adminChoiceReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SELECT_PARAMETER:
      return {
        ...state,
        selectedName: payload.label,
        selectedParam: payload.param,
      };
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.parameter]: [],
          [payload.parameter.split('/')[0]]: payload.data,
        },
      };
    // adding flag selected to chosen mailing and deleting it from other mailings
    case SET_SELECTED_MAILING:
      return {
        ...state,
        data: {
          ...state.data,
          mailings: state.data.mailings.map((item) => ({
            ...item,
            selected: item.key_name === payload,
          })),
        },
      };
    default:
      return state;
  }
};

export default adminChoiceReducer;
