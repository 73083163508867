// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cards__header {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-template-areas: 'header data1 data2 data3 administate';
  /* max-height: 50vh; */
  /* min-height: var(--sadr-font-size-150); */
  gap: var(--sadr-font-size-20);
  background: var(--sadr-background-secondary);
  box-shadow: 0 var(--sadr-font-size-5) var(--sadr-font-size-20) var(--sadr-border-shadow);
  border-radius: var(--sadr-font-size-12);
  margin: var(--sadr-font-size-10);
  padding: var(--sadr-font-size-20);

  box-sizing: border-box;
  justify-content: space-between;
}
.cards__header div:nth-child(1) {
  grid-area: header;
}
.cards__header div:nth-child(2) {
  grid-area: data1;
}
.cards__header div:nth-child(3) {
  grid-area: data2;
}
.cards__header div:nth-child(4) {
  grid-area: data3;
}
.cards__header div:nth-child(6) {
  grid-area: administate;
}

/* @media screen and (max-width: 1650px) {
  .cards__header__rebuild {
    grid-template-columns:
      minmax(var(--sadr-font-size-210), 1fr)
      minmax(var(--sadr-font-size-210), 1fr)
      minmax(var(--sadr-font-size-210), 1fr);
    grid-template-areas:
      'header empty administate'
      'data1 data2 data3';
    min-height: fit-content;
    overflow-x: auto;
  }
} */
@media screen and (max-width: 1260px) {
  .cards__header {
    overflow-x: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/CardHeader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,+CAA+C;EAC/C,2DAA2D;EAC3D,sBAAsB;EACtB,2CAA2C;EAC3C,6BAA6B;EAC7B,4CAA4C;EAC5C,wFAAwF;EACxF,uCAAuC;EACvC,gCAAgC;EAChC,iCAAiC;;EAEjC,sBAAsB;EACtB,8BAA8B;AAChC;AACA;EACE,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,sBAAsB;AACxB;;AAEA;;;;;;;;;;;;GAYG;AACH;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".cards__header {\n  display: grid;\n  grid-template-columns: auto auto auto auto auto;\n  grid-template-areas: 'header data1 data2 data3 administate';\n  /* max-height: 50vh; */\n  /* min-height: var(--sadr-font-size-150); */\n  gap: var(--sadr-font-size-20);\n  background: var(--sadr-background-secondary);\n  box-shadow: 0 var(--sadr-font-size-5) var(--sadr-font-size-20) var(--sadr-border-shadow);\n  border-radius: var(--sadr-font-size-12);\n  margin: var(--sadr-font-size-10);\n  padding: var(--sadr-font-size-20);\n\n  box-sizing: border-box;\n  justify-content: space-between;\n}\n.cards__header div:nth-child(1) {\n  grid-area: header;\n}\n.cards__header div:nth-child(2) {\n  grid-area: data1;\n}\n.cards__header div:nth-child(3) {\n  grid-area: data2;\n}\n.cards__header div:nth-child(4) {\n  grid-area: data3;\n}\n.cards__header div:nth-child(6) {\n  grid-area: administate;\n}\n\n/* @media screen and (max-width: 1650px) {\n  .cards__header__rebuild {\n    grid-template-columns:\n      minmax(var(--sadr-font-size-210), 1fr)\n      minmax(var(--sadr-font-size-210), 1fr)\n      minmax(var(--sadr-font-size-210), 1fr);\n    grid-template-areas:\n      'header empty administate'\n      'data1 data2 data3';\n    min-height: fit-content;\n    overflow-x: auto;\n  }\n} */\n@media screen and (max-width: 1260px) {\n  .cards__header {\n    overflow-x: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
