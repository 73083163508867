import axiosInstance from '../../config/axios.config';

//---- Recollecting of form data, according to request parameters
const replaceKeysAndData = (formData) => {
  if (typeof formData !== 'object' || formData === null) {
    throw new TypeError('Expected formData to be a non-null object');
  }
  const updatedFormData = {};
  for (const key of Object.keys(formData)) {
    // Check if the key ends with '_id' and replace it with 'Id'
    if (key.endsWith('_id')) {
      const newKey = key.replace(/_id$/, 'Id');
      if (formData[key]) {
        updatedFormData[newKey] = formData[key];
      }
      // Replace value for "device" if it is empty string or null to false
    } else if (key === 'device' && !formData[key]) {
      updatedFormData[key] = false;
    } else if (key === 'position_full_name') {
      updatedFormData.positionFullName = formData[key];
    } else if (key === 'full_name') {
      updatedFormData.fullName = formData[key];
    } else if (formData[key]) {
      updatedFormData[key] = formData[key];
    }
  }
  return updatedFormData;
};
const addNewData = async (formData, dataType) => {
  try {
    const fixedFormData = replaceKeysAndData(formData);
    const response = await axiosInstance.post(`/api/data/${dataType}`, fixedFormData);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';
    return errorMessage;
  }
};
const updateData = async (formData, dataType, updateDeviceId) => {
  try {
    const fixedFormData = replaceKeysAndData(formData);
    const response = await axiosInstance.put(`/api/data/${dataType}/${updateDeviceId}`, fixedFormData);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';
    console.error(error);
    return errorMessage;
  }
};
export { addNewData, updateData };
