import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import CustomButtonEdit from '../CustomElements/CustomButtonEdit';
import { Tab, Tabs } from '../CustomElements/Tabs';
import RailChains from './RailChains';
import Adjacency from './Adjacency';
import { useInfocard } from './Common';
import Semaphores from './Semaphores';
import Modal from '../Modal/Modal';

import {
  clearAdjacencyState,
  clearRailChainsState,
  clearSemaphoresState,
  editModeFromServer,
} from '../../redux/actions/infocards.action';
import useUrlNavigate from '../../customHook/useUrlNavigate';
import { useRole } from '../../customHook/useRole';
import CustomButton from '../CustomElements/CustomButton';
import CustomContainer from '../CustomElements/CustomContainer';
import ConfirmationPage from '../CustomElements/ConfirmationPage';

export default function InfocardView() {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  // Закрыть модальное окно
  const closeModal = () => {
    setIsOpen(false);
  };

  // роли пользователя
  const isAdmin = useRole('admin');

  // хук для навигации
  const changeURL = useUrlNavigate();

  // получаем данные для отображения инфокарты
  const {
    name,
    road_name,
    department_name,
    max_speed,
    min_dist,
    alert_dist,
    direction,
    station,
    road,
    is_disabled,
    is_station,
  } = useInfocard();
  const noDataAlias = 'н/д';
  const roadName = road_name || noDataAlias;
  const departmentName = department_name || noDataAlias;
  const maxSpeed = max_speed ? `${max_speed} км/ч` : noDataAlias;
  const minDistance = min_dist ? `${min_dist} м` : noDataAlias;
  const alertDistance = alert_dist ? `${alert_dist} м` : noDataAlias;

  const editMode = () => {
    dispatch(editModeFromServer({ id: station, is_disabled: !is_disabled }));
    closeModal();
  };
  // при закрытии инфокарты сбрасываем стейт со смежностями, рц и семафорами обновляем
  useEffect(() => {
    return () => {
      dispatch(clearAdjacencyState());
      dispatch(clearRailChainsState());
      dispatch(clearSemaphoresState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <div className="d-flex flex-column flex-nowrap w-100 overflow-hidden h-100">
        <div id="card" className="cards__header cards__header__rebuild">
          <div className="infocard-card-block">
            {/* <h4 className="infocard-card-title">{name}</h4> */}
            <h4 className={`infocard-card-title ${!is_disabled ? 'status-available' : 'status-unavailable'}`}>
              {name}
            </h4>
            <h4 className={`infocard-card-title-ison ${!is_disabled ? 'status-available' : 'status-unavailable'}`}>
              {!is_disabled ? 'Доступно для работы бригад' : 'Недоступно для работы бригад'}
            </h4>
          </div>
          <div className="infocard-text text-left">
            <span>
              <b>Дорога: </b>
              {roadName}
            </span>
            <span>
              <b>Подразделение: </b>
              {departmentName}
            </span>
            <span>
              <b>Тип: </b>
              {is_station ? 'Станция' : 'Перегон'}
            </span>
            <span>
              <b>Направление: </b>
              {direction}
            </span>
          </div>
          <div className="infocard-text text-left">
            <span>
              <b>Разрешенная макс. скорость: </b>
              {maxSpeed}
            </span>
            <span>
              <b>Минимальное расстояние оповещения: </b>
              {minDistance}
            </span>
            <span>
              <b>Расст. тревоги при приближ. ПС с исправным УИППС: </b>
              {alertDistance}
            </span>
          </div>
          <span />
          {isAdmin && (
            <CustomButtonEdit
              className="device-edit-btn ms-auto mb-auto w-auto"
              onClick={() =>
                changeURL((u) => {
                  u.pathname = u.pathname.replace('/view', '/edit');
                })
              }
            />
          )}
          {isAdmin && (
            <div className="btn-is-on">
              <CustomButton
                width="var(--sadr-font-size-135)"
                minHeight="var(--sadr-font-size-40)"
                height="var(--sadr-font-size-40)"
                color="var(--sadr-error)"
                text={!is_disabled ? 'Отключить' : 'Включить'}
                onClick={() => {
                  setIsOpen(true);
                }}
              />
            </div>
          )}
        </div>
        <Tabs>
          <Tab name="Рельсовые цепи" path="/rail_chain">
            <RailChains />
          </Tab>
          <Tab name="Смежность" path="/adjacency">
            <Adjacency />
          </Tab>
          <Tab name="Светофоры" path="/semaphores">
            <Semaphores station={station} road={road} />
          </Tab>
        </Tabs>
      </div>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={closeModal} width="700px" height="450px">
          <CustomContainer>
            <ConfirmationPage
              onConfirm={editMode}
              onCancel={closeModal}
              confirmButtonText={'Подтвердить'}
              cancelButtonText={'Отмена'}
              confirmText={
                !is_disabled ? `Вы уверены, что хотите запретить доступ ?` : `Вы уверены, что хотите разрешить доступ ?`
              }
              btnWidth="var(--sadr-font-size-280)"
            />
          </CustomContainer>
        </Modal>
      )}
    </>
  );
}
