// Импорт React хуков, используемых в нашем кастомном хуке
import { useEffect, useState } from 'react';

/**
 * Пользовательский хук для определения, были ли изменения в данных формы.
 *
 * @param {Object} initialFormData - Начальные данные формы.
 * @param {Object} formData - Текущие данные формы.
 * @param {boolean} shouldCheck - Флаг, указывающий, нужно ли проверять изменения.
 * @returns {boolean} - Возвращает true, если были изменения в данных формы.
 */
const useFormDataChanged = (initialFormData, formData, shouldCheck) => {
  /**
   * Нормализует значение номера телефона, удаляя пробелы и символы.
   *
   * @param {string} phone - Номер телефона для нормализации.
   * @returns {string} - Нормализованный номер телефона.
   */
  const normalizePhone = (phone) => phone.replace(/\D/g, '');

  // Использование useState для отслеживания, были ли изменения в данных формы.
  const [isChanged, setIsChanged] = useState(false);

  /**
   * Сравнивает два значения с учетом особых случаев (null, '', Date, File).
   *
   * @param {*} value1 - Первое значение для сравнения.
   * @param {*} value2 - Второе значение для сравнения.
   * @returns {boolean} - Возвращает true, если значения считаются эквивалентными.
   */
  const compareValues = (value1, value2, key) => {
    // Нормализуем и сравниваем номера телефонов для ключей sim1 и sim2
    if (['sim1', 'sim2'].includes(key)) {
      return normalizePhone(value1 || '') === normalizePhone(value2 || '');
    }
    // Специальное условие, считающее null и пустую строку ('') эквивалентными.
    if ((value1 === null && value2 === '') || (value1 === '' && value2 === null)) {
      return true;
    }
    // Сравнение значений, если они оба являются экземплярами Date.
    if (value1 instanceof Date && value2 instanceof Date) {
      return value1.getTime() === value2.getTime();
    }
    // Сравнение файлов по имени и размеру.
    else if (value1 instanceof File && value2 instanceof File) {
      return value1.name === value2.name && value1.size === value2.size;
    }
    // Сравнение булевых значений.
    else if (typeof value1 === 'boolean' || typeof value2 === 'boolean') {
      return value1 === value2;
    }
    // Стандартное сравнение для других типов, включая случай, когда оба значения undefined.
    else {
      return value1 === value2 || (value1 === undefined && value2 === undefined);
    }
  };

  // useEffect для отслеживания изменений в formData и initialFormData.
  useEffect(() => {
    // Если проверка активирована флагом shouldCheck
    if (shouldCheck) {
      // Выполняем проверку наличия изменений в каждом ключе formData
      const isFormDataChanged = Object.keys(initialFormData).some(
        (key) => !compareValues(formData[key], initialFormData[key], key)
      );
      // Обновляем состояние isChanged в зависимости от наличия изменений
      setIsChanged(isFormDataChanged);
    } else {
      // Если проверка не требуется, устанавливаем isChanged в false
      setIsChanged(true);
    }
  }, [formData, initialFormData, shouldCheck]);

  // Возвращаем текущее состояние isChanged
  return isChanged;
};

export default useFormDataChanged;
