import { GET_REPORT, INIT_REPORTS } from '../type';
import axiosInstance from '../../config/axios.config';
import { success } from './loader.action';
import { addError } from './error.action';
import { downloadFile } from '../../utils/downloadFile';
import { convertMillisecondsToDate } from '../../utils/convertToDate';
import { handleRequestError } from '../../utils/handleRequestError';

export const initReports = (options) => ({
  type: INIT_REPORTS,
  payload: options,
});

export const initReportFromServer = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance('/api/documentation');
    if (data) {
      dispatch(initReports(data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getReport = (options) => ({
  type: GET_REPORT,
  payload: options,
});

export const getReportFromServer = (payload) => async (dispatch) => {
  try {
    const { data } = await axiosInstance.post('/api/documentation', payload);
    if (data) {
      dispatch(getReport(data));
      dispatch(success());
    }
  } catch (error) {
    const message = error?.response?.data || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';
    dispatch(addError(message));
    console.log(error);
  }
};

//функция для получения отчета с сервера
export const getContractReportFromServer = (payload, divisionName) => async (dispatch) => {
  try {
    // Определяем URL на основе переданного параметра
    const url = payload?.urlPath ? `/api/documentation/${payload.urlPath}` : '/api/documentation';
    // Отправляем POST запрос с указанием, что ответ будет в формате blob
    const response = await axiosInstance.post(url, payload, { responseType: 'blob' });

    const period = `${convertMillisecondsToDate(payload?.startDate, false)} - ${convertMillisecondsToDate(
      payload?.endDate,
      false
    )}`;

    let filename = undefined;
    switch (payload?.reportType) {
      case 'onOff':
        filename = `Включение_выключение ${period}.docx`;
        break;
      case 'onOff10':
        filename = `Включение_выключение - 10 мин ${period}.docx`;
        break;
      case 'conn':
        filename = `Подключение_отключение ${period}.xlsx`;
        break;
      case 'restrict':
        filename = `Ограждение ${period}.xlsx`;
        break;
      case 'summary':
        filename = `Сводный отчет за период ${period}.docx`;
        break;
      case 'detailed':
        filename = `Детализированный отчет за период ${period}.docx`;
        break;
      case 'service':
        filename = `Отчет об оказанных услугах по ${divisionName} за период ${period}.docx`;
        break;
      case 'serviceXlsx':
        filename = `Отчет об оказанных услугах по ${divisionName} за период ${period}.xlsx`;
        break;
      case 'general':
        filename = `Общий отчет за период ${period}.xlsx`;
        break;
      default:
        filename = `Отчет за период ${period}.docx`;
    }

    const { data } = response;

    if (data) {
      // Если данные существуют, сигнализируем об успехе и инициируем скачивание файла
      dispatch(success());
      downloadFile(data, filename);
    }
  } catch (error) {
    // В случае ошибки передаем её в функцию обработки
    handleRequestError(error, dispatch);
  }
};

export const getReportBrigadeFromServer = (payload) => async (dispatch) => {
  try {
    const { data } = await axiosInstance(`/reports/${payload}`, { responseType: 'blob' });
    if (data) {
      const filename = `Отчет по бригадам за ${payload}.txt`;
      downloadFile(data, filename);
      dispatch(success());
    }
  } catch (error) {
    let errorMessage = 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';
    if (error?.response?.data) {
      try {
        const errorText = await error.response.data.text(); // Преобразование ответа в текст
        const errorJson = JSON.parse(errorText); // Парсинг текста в JSON
        errorMessage = errorJson.error || errorMessage; // Использование сообщения об ошибке из JSON, если оно есть
      } catch (parseError) {
        errorMessage = 'Ошибка при анализе ответа сервера.'; // Отдельный блок обработки ошибок парсинга
      }
    }
    dispatch(addError({ error: errorMessage }));
    console.error('Error downloading detail report:', error);
  }
};

export const getDetailReportBrigadeFromServer = (payload) => async (dispatch) => {
  try {
    const { data } = await axiosInstance(`/connections?date=${payload.date}&brigadeId=${payload?.mrmId}`, {
      responseType: 'blob',
    });
    if (data) {
      const filename = `Детализация подключений ИУР ${payload?.mrmId} за ${payload.date}.txt`;
      downloadFile(data, filename);
      dispatch(success());
    }
  } catch (error) {
    let errorMessage = 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';
    if (error?.response?.data) {
      try {
        const errorText = await error.response.data.text(); // Преобразование ответа в текст
        const errorJson = JSON.parse(errorText); // Парсинг текста в JSON
        errorMessage = errorJson.error || errorMessage; // Использование сообщения об ошибке из JSON, если оно есть
      } catch (parseError) {
        errorMessage = 'Ошибка при анализе ответа сервера.'; // Отдельный блок обработки ошибок парсинга
      }
    }

    dispatch(addError({ error: errorMessage }));
    console.error('Error downloading detail report:', error);
  }
};

// export const getContractReportFromServer = (payload) => async (dispatch) => {
//   try {
//     const url = payload?.urlPath
//     ? `/api/documentation/${payload.urlPath}`
//     : '/api/documentation';

//     const response = await axiosInstance.post(url, payload, { responseType: 'blob' })
//     const contentDisposition = response.headers['content-disposition']
//     let format = contentDisposition?.split('format=')[1]?.replaceAll('"', '')
//     if (!format) {
//       console.log('format not found in content-disposition, using default docx')
//       format = 'docx'
//     }
//     const { data } = response
//     if (data) {
//       dispatch(success())
//       const filename = `Отчет за период ${convertMillisecondsToDate(payload?.startDate, false)}-${convertMillisecondsToDate(payload?.endDate, false)}.${format}`;
//       downloadFile(data, filename);
//     }
//   } catch (error) {
//     let message = 'Возникла непредвиденная ошибка, обратитесь к администратору системы.'
//     if (error.response && error.response.data) {
//       // Create a new FileReader to read the blob
//       const reader = new FileReader();
//       // Define a promise to handle the async reading process
//       const errorMessage = await new Promise((resolve, reject) => {
//           reader.onloadend = () => resolve(reader.result);
//           reader.onerror = reject;
//           reader.readAsText(error.response.data);
//       });
//       try {
//         message = JSON.parse(errorMessage)?.error
//       } catch(e) {
//         console.log('Invalid json:', errorMessage)
//       }
//     }
//     dispatch(addError({error: message}))
//     console.log(error);
//   }
// }
