import axiosInstance from '../../config/axios.config';
import { FETCH_DATA_REQUEST, SELECT_PARAMETER, SET_SELECTED_MAILING } from '../type';

const setSelected = (payload) => ({
  type: SELECT_PARAMETER,
  payload,
});

const fetchDataRequest = (payload) => ({
  type: FETCH_DATA_REQUEST,
  payload,
});

const getData = (parameter) => async (dispatch) => {
  try {
    const { data } = await axiosInstance(`/api/data/${parameter}`);

    // Если параметр departments, преобразуем ключи
    const processedData =
      data && parameter === 'departments'
        ? data.map(({ shift_start: shiftStart, shift_end: shiftEnd, ...rest }) => ({
            ...rest,
            shiftStart,
            shiftEnd,
          }))
        : data;

    if (processedData) {
      dispatch(
        fetchDataRequest({
          data: processedData,
          parameter: parameter,
        })
      );
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchSelectedMailing = (payload) => ({
  type: SET_SELECTED_MAILING,
  payload,
});

const setSelectedMailing = (payload) => async (dispatch) => {
  try {
    await dispatch(fetchSelectedMailing(payload));
    await dispatch(getData(`emails/${payload}`));
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const deleteEmail = async (emailId) => {
  try {
    const result = await axiosInstance.delete(`/api/data/emails/${emailId}`);
    if (result.status === 200) {
      return result;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export { getData, setSelected, setSelectedMailing, deleteEmail };
