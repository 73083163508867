import { React, useState, useMemo, useEffect } from 'react';
import '../../styles/EditDevice.css';
import { useDispatch, useSelector } from 'react-redux';
import { editDeviceFromServer } from '../../redux/actions/devices.action';
import CustomButton from '../CustomElements/CustomButton';
import CustomTitle from '../CustomElements/CustomTitle';
import CustomContainer from '../CustomElements/CustomContainer';
import FormContainer from '../CustomElements/FormContainer';
import Selector from '../CustomElements/Selector';
import DateSelector from '../CustomElements/DateSelector';
import PhoneInput from '../CustomElements/PhoneInput';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import { useButtonValidation } from '../../customHook/useButtonValidation';
import { handleUniversalChange, createFormData } from '../../utils/formUtils';
import { convertToDate } from '../../utils/convertToDate';
import { validateSimNumbers } from '../../utils/validateSimNumbers';
import { addError } from '../../redux/actions/error.action';
import useFormDataChanged from '../../customHook/useFormDataChanged';
import { load, remove } from '../../redux/actions/loader.action';
import { initUippsAndroidIdFromServer } from '../../redux/actions/uipps.action';
import { initIurAndroidIdFromServer } from '../../redux/actions/devices.action';

// компонент изменения устройства

function EditDevice({ closeModalDevice, currentDevice, typeUipps, t }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // Получаем опции
  const optionsForSelect = useSelector((state) => state.options);

  // Получаем данные о процессе
  const loader = useSelector((state) => state.loader);

  // Получение сообщения об ошибке из глобального состояния
  const error = useSelector((state) => state.error);

  // Состояния для кнопки и уведомления об успешном добавлении
  const [editSuccess, setEditSuccess] = useState(false);

  // Состояние для формы
  const initialFormData = useMemo(
    () => ({
      deviceId: currentDevice?.id,
      sim1: currentDevice?.sim1 || '',
      manufactureDate: convertToDate(currentDevice.manufacture_date) || '',
      sim2: currentDevice?.sim2 || '',
      androidId: currentDevice?.android_id || '',
    }),
    [currentDevice]
  );

  const [formData, setFormData] = useState(initialFormData);

  //проверяем были ли изменения в форме 
  const isFormEdit = useFormDataChanged(initialFormData, formData, true);

  // Вычисляем optionalFields на основе formData.deviceId
  const optionalFields = useMemo(() => {
    if (formData.deviceId >= 300000 && formData.deviceId <= 399999) {
      return ['sim1', 'sim2', 'androidId'];
    }
    return ['sim1', 'sim2'];
  }, [formData.deviceId]);

  //Функция для проверки валидности формы
  const isButtonDisabled = useButtonValidation(formData, optionalFields);

  useEffect(() => {
    console.log('formData', formData);
  }, [formData]);

  // Обработчик отправки формы
  const handlerSubmit = (event) => {
    event.preventDefault();
    const payload = createFormData(formData);
    dispatch(load());
    dispatch(editDeviceFromServer(payload, formData, typeUipps));
  };

  // Закрывает модальное окно после успешного изменения устройства
  const handlerCloseModal = () => {
    closeModalDevice();
    setEditSuccess(false);
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    const checkSim = validateSimNumbers(formData, setFormData);
    if (checkSim) {
      dispatch(addError({ error: checkSim }));
    } else if (!checkSim) {
      setEditSuccess(!editSuccess);
    }
  };

  //отслеживаем выполнение запроса
  useEffect(() => {
    // Если нет ошибок, закрываем модальное окно
    if (!loader?.isLoading && loader?.isSuccess) {
      //Меняем флаг для отображения сообщения что устройство изменено
      handlerCloseModal();
    }
    // при закрытии компонента чистим стейт загрузки
    return () => {
      dispatch(remove());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader?.isLoading, loader?.isSuccess, error]);

  // отправляем запрос на получений списка androidId и документов при открытии формы
  useEffect(() => {
    if (typeUipps) {
      dispatch(initUippsAndroidIdFromServer());
    } else {
      dispatch(initIurAndroidIdFromServer());
    }
  }, [dispatch, typeUipps]);

  return (
    <CustomContainer height="100%">
      <CustomTitle
        titleText={t?.editDeviceTitle || 'Изменение данных'}
        deviceType={currentDevice.type}
        deviceId={currentDevice.id}
      />
      {editSuccess && (
        <ConfirmationPage
          confirmText={t?.confirmEditDeviceText || 'Вы уверены, что хотите изменить данные устройства?'}
          confirmButtonText={t?.confirmButton || 'Изменить'}
          cancelButtonText={t?.cancelButton || 'Отменить'}
          onConfirm={handlerSubmit} // Функция, которая должна быть вызвана при подтверждении
          onCancel={handlerCloseModal} // Функция для закрытия модального окна или отмены действия
        />
      )}
      {!editSuccess && (
        <>
          <FormContainer>
            <PhoneInput
              column="left"
              label={t?.labelSim1 || 'SIM1'}
              name="sim1"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              value={formData.sim1}
            />
            <DateSelector
              column="left"
              width="100%"
              height="var(--sadr-font-size-60)"
              label={t?.labelManufactureDate || 'Дата производства *'}
              selectedDate={formData.manufactureDate}
              setSelectedDate={(date) =>
                handleUniversalChange(formData, setFormData, null, 'manufactureDate', date, 'date')
              }
              name="manufactureDate"
              placeholder={t?.placeholderManufactureDate || 'Выберите дату производства'}
              maxDate={new Date()}
            />
            <PhoneInput
              column="right"
              label={t?.labelSim2 || 'SIM2'}
              name="sim2"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              value={formData.sim2}
            />
            {![3, 2].includes(currentDevice?.type_id) && (
              <Selector
                column="right"
                options={typeUipps ? optionsForSelect?.uippsAndroidId : optionsForSelect?.iurAndroidId}
                value={
                  optionsForSelect?.[typeUipps ? 'uippsAndroidId' : 'iurAndroidId']?.find(
                    (option) => option.value === formData.androidId
                  ) || { value: formData.androidId, label: formData.androidId } ||
                  ''
                }
                onChange={(selectedOption) =>
                  handleUniversalChange(formData, setFormData, null, 'androidId', selectedOption, 'select')
                }
                placeholder={t?.placeholderSelectAndroidId || 'Выберите android_id'}
                label={t?.labelAndroidId || 'ANDROID_ID *'}
                width="100%"
                height="var(--sadr-font-size-60)"
                isMulti={false}
                name="androidId"
                optionText={
                  typeUipps
                    ? t?.textNoUnauthorizedUipps || 'Нет неавторизованных УИППС'
                    : t?.textNoUnauthorizedIur || 'Нет неавторизованных ИУР'
                }
              />
            )}
          </FormContainer>
          <CustomButton
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            text={t?.buttonSaveChanges || 'Сохранить изменения'}
            disabled={isButtonDisabled || !isFormEdit}
            onClick={handleButtonClick}
            isButton
          />
        </>
      )}
    </CustomContainer>
  );
}
export default EditDevice;
