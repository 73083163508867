import { useState } from 'react';
import '../../styles/DownloadReferenceForm.css';
import Input from '../CustomElements/Input';
import { createFormData, handleUniversalChange } from '../../utils/formUtils';
import CustomTitle from '../CustomElements/CustomTitle';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import CustomButton from '../CustomElements/CustomButton';
import { useButtonValidation } from '../../customHook/useButtonValidation';
import { useDispatch } from 'react-redux';

import { newReferenceFromServer, requestInfocards } from '../../redux/actions/infocards.action';
import { addError } from '../../redux/actions/error.action';
import Preloader from '../CustomElements/Preloader';

function DownloadReferenceForm({ closeModal, formData }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  const [reference, setReference] = useState({ file: '', name: '' });
  const [isLoading, setIsLoading] = useState(false);
  // Состояния уведомления об успешном добавлении
  const [addSuccess, setAddSuccess] = useState(false);
  const [errors, setErrors] = useState([]);

  // Закрывает модальное окно после успешного добавления
  const handlerCloseModal = () => {
    setErrors([]);
    setAddSuccess(false);
    closeModal();
    setIsLoading(false);
  };

  // Обработчик отправки формы
  const handlerSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const payload = createFormData(reference);
    try {
      // Вызываем функцию и ждём завершения
      const result = await newReferenceFromServer(payload);
      // Если выполнение успешно, записываем успех
      if (result) {
        setAddSuccess(true);
        setIsLoading(false);
        dispatch(requestInfocards(formData));
      }
    } catch (error) {
      // Обрабатываем ошибку
      if (error.response?.status === 400 && Array.isArray(error.response.data.errors)) {
        // Если статус 400 и есть массив ошибок
        setErrors(error.response.data.errors);
        setIsLoading(false);
      } else {
        // Для других ошибок вызываем addError
        dispatch(
          addError({ error: error.message || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.' })
        );
        setIsLoading(false);
      }
    }
  };
  // Функция для проверки валидности формы
  const isButtonDisabled = useButtonValidation(reference);

  return (
    <section className="reference">
      <CustomTitle titleText={'ДОБАВЛЕНИЕ НОВОГО СПРАВОЧНИКА'} />
      {addSuccess && (
        <ConfirmationPage confirmText={'Новый справочник добавлен!'} onConfirm={handlerCloseModal} addNew={'Закрыть'} />
      )}
      {!addSuccess && (
        <>
          <Input
            column="left"
            placeholder="Выберите файл"
            onChange={(event) => {
              handleUniversalChange(reference, setReference, event, null, null, 'file', 'name');
              setIsLoading(false);
              setErrors([]);
            }}
            onRemove={() => {
              handleUniversalChange(reference, setReference, null, 'isRemove', null, null, 'file', 'name');
              setIsLoading(false);
              setErrors([]);
            }}
            type="file"
            name="file"
            label="Файл справочника *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
          />
          <Preloader isLoading={isLoading} />
          {errors.length > 0 && (
            <>
              <CustomTitle titleText={'ИНФОРМАЦИЯ ОБ ОШИБКАХ'} className="highlighted-title" />
              <div className="error-list">
                <ol>
                  {errors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ol>
              </div>
            </>
          )}
          <CustomButton
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            text="Добавить файл"
            disabled={isButtonDisabled || errors.length > 0 || isLoading}
            isButton
            margin={'auto 0 var(--sadr-font-size-40) 0'}
            onClick={handlerSubmit}
          />
        </>
      )}
    </section>
  );
}

export default DownloadReferenceForm;
