import { React, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../../styles/FilterForBrigades.css';
import Selector from '../CustomElements/Selector';
import Accordeon from '../CustomElements/Accordeon';
import Input from '../CustomElements/Input';
import DateRangePicker from '../CustomElements/DateRangePicker';

import { useRole } from '../../customHook/useRole';
import useOptionsLoader from '../../customHook/useOptionsLoader';

import { handleUniversalChange } from '../../utils/formUtils';
import {
  initDepartmenOptionsFromServer,
  initOptionsDirectoratesFromServer,
  initOptionsSectionFromServer,
} from '../../redux/actions/options.action';
import { clearMapData } from '../../redux/actions/mapData.action';

function FilterForBrigades({
  setIsViewList,
  isViewList,
  setIsViewMap,
  isViewMap,
  formData,
  setFormData,
  showBtnReset,
  resetFilters,
  t,
}) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // получаем опции из стора
  const optionsForSelect = useSelector((state) => state.options);

  //хук для запроса опций для дирекций и контроля селекта
  const messageForSelectDirectorates = t?.messageForSelectDirectorates || 'Выберите дорогу';
  const directoratesControl = useOptionsLoader(
    formData,
    setFormData,
    ['directorate_id', 'department_id', 'station_id'],
    'road_id',
    initOptionsDirectoratesFromServer,
    messageForSelectDirectorates
  );

  //хук для запроса опций для подразделений и контроля селекта
  const messageForSelectDepartments = t?.messageForSelectDepartments || 'Выберите дирекцию';
  const departmentsControl = useOptionsLoader(
    formData,
    setFormData,
    ['department_id'],
    'directorate_id',
    initDepartmenOptionsFromServer,
    messageForSelectDepartments
  );

  //хук для запроса опций для станций и контроля селекта
  const messageForSelectStation = t?.messageForSelectStation || 'Выберите дорогу';
  const stationControl = useOptionsLoader(
    formData,
    setFormData,
    ['station_id'],
    'road_id',
    initOptionsSectionFromServer,
    messageForSelectStation
  );

  //цвет кнопок, события кнопок
  const [colorBtn, setColorBtn] = useState(false);
  const handleButtonClickList = () => {
    if (!isViewList) {
      setIsViewList((prev) => !prev);
      setIsViewMap((prev) => !prev);
      setColorBtn((prev) => !prev);
      resetFilters();
      dispatch(clearMapData());
    }
  };
  const handleButtonClickMap = () => {
    if (!isViewMap) {
      setIsViewMap((prev) => !prev);
      setIsViewList((prev) => !prev);
      setColorBtn((prev) => !prev);
      resetFilters();
      dispatch(clearMapData());
    }
  };

  const isAdmin = useRole('admin');
  return (
    <Accordeon header={t?.accordionHeader} hideAbove={1650} qParamName={'brigades_acc'}>
      <div className="accordeon_buttons__list-map">
        <button
          type="button"
          className={`accordeon_button ${!colorBtn ? 'accordeon_button__active' : ''}`}
          onClick={handleButtonClickList}
        >
          {t?.listButton}
        </button>
        <button
          type="button"
          className={`accordeon_button ${colorBtn ? 'accordeon_button__active' : ''}`}
          onClick={handleButtonClickMap}
        >
          {t?.mapButton}
        </button>
      </div>
      <button
        type="button"
        className={`accordeon__reset-filters ${showBtnReset && 'accordeon__reset-filters_visible'}`}
        onClick={resetFilters}
      >
        <span className="accordeon__reset-filters_text">{t?.resetFiltersButton}</span>
        <img className="accordeon__reset-filters_icon" src="/image/close.svg" alt={t?.closeButtonText}></img>
      </button>
      <form className="accordeon__form" id="" method="POST" action="#" autoComplete="off">
        <div className="accordeon__select-container" id="">
          <Input
            label={t?.idLabel}
            placeholder={t?.idPlaceholder}
            onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 6)}
            value={formData?.id}
            name="id"
          />
          <Input
            placeholder={t?.responsiblePlaceholder}
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            value={formData?.responsible}
            label={t?.responsibleLabel}
            name="responsible"
          />
          {isViewList && (
            <Selector
              options={optionsForSelect.status}
              onChange={(selectedOption) => {
                handleUniversalChange(formData, setFormData, null, 'isWorking', selectedOption, 'select');
              }}
              placeholder={t?.placeholderSelectStatus}
              isMulti={false}
              label={t?.status}
              value={
                formData.isWorking
                  ? optionsForSelect?.status?.find((option) => option.value === formData.isWorking)
                  : null
              }
              name="isWorking"
            />
          )}

          <Selector
            options={optionsForSelect.roads}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'road_id', selectedOption, 'select');
            }}
            placeholder={t?.roadPlaceholder}
            isMulti={false}
            label={t?.roadLabel}
            value={
              formData.road_id ? optionsForSelect?.roads?.find((option) => option.value === formData.road_id) : null
            }
            name="road_id"
          />
          <Selector
            options={optionsForSelect?.directorates}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'directorate_id', selectedOption, 'select');
            }}
            placeholder={t?.directoratePlaceholder}
            label={t?.directorateLabel}
            value={
              formData.directorate_id
                ? optionsForSelect?.directorates?.find((option) => option.value === formData.directorate_id)
                : null
            }
            isMulti={false}
            name="directorate_id"
            isDisabled={directoratesControl?.isSelectorDisabled}
            message={directoratesControl?.message}
            customClick={directoratesControl?.onSelectorChange}
          />
          <Selector
            options={optionsForSelect.departments}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'department_id', selectedOption, 'select');
            }}
            placeholder={t?.departmentPlaceholder}
            isMulti={false}
            label={t?.departmentLabel}
            value={
              formData.department_id
                ? optionsForSelect?.departments?.find((option) => option.value === formData.department_id)
                : null
            }
            name="department_id"
            isDisabled={departmentsControl?.isSelectorDisabled}
            message={departmentsControl?.message}
            customClick={departmentsControl?.onSelectorChange}
          />
          <Selector
            options={optionsForSelect?.stations}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'station_id', selectedOption, 'select');
            }}
            value={
              formData.station_id
                ? optionsForSelect.stations?.find((option) => option.value === formData.station_id)
                : null
            }
            isMulti={false}
            placeholder={t?.stationPlaceholder}
            name="station_id"
            label={t?.stationLabel}
            isDisabled={stationControl?.isSelectorDisabled}
            message={stationControl?.message}
            customClick={stationControl?.onSelectorChange}
          />
          {isAdmin && (
            <DateRangePicker
              label={t?.dateLabel}
              formData={formData}
              setFormData={setFormData}
              startDateField="start" // имя поля для даты начала, с дефолтным значением
              endDateField="finish" // имя поля для даты окончания, с дефолтным значением
              placeholder={t?.datePlaceholder}
              name="date_period"
              maxDate={new Date()}
            />
          )}
          <div className="divider" />
          <Input
            placeholder={t?.devicePlaceholder}
            onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 6)}
            value={formData?.device_id}
            label={t?.deviceLabel}
            name="device_id"
            maxLength={6}
          />
        </div>
      </form>
    </Accordeon>
  );
}

export default FilterForBrigades;
