/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../CustomElements/CustomButton';
import { useEffect, useMemo, useState } from 'react';
import { setSelectedMailing } from '../../redux/actions/adminChoice.action';

/**
 * Renders a panel of buttons based on the selected parameter.
 * Fetches button data from the Redux store and maintains the selected state.
 * When a button is clicked, it updates the selected mailing in the store.
 *
 * @param {Object} props - The component props.
 * @param {string} props.selectedParam - Determines which set of buttons to display ('emails' for mailings or other).
 *
 * @returns {JSX.Element} A div containing a list of buttons, each representing an item from the fetched data.
 */

function ButtonsPanelInBlock({ selectedParam }) {
  const [btnsNames, setBtnsNames] = useState([]);
  // internal state to color chosen button
  const [selectedValue, setSelectedValue] = useState('');
  const dispatch = useDispatch();

  // get data about all mailings from store
  const buttonsData = useSelector(
    (state) => state.adminChoiceData.data[selectedParam === 'emails' ? 'mailings' : selectedParam]
  );
  // get if selected mailing exists from store
  const selectedMailing = useSelector((state) =>
    state.adminChoiceData.data.mailings?.find((mailing) => mailing.selected)
  );
  useMemo(() => {
    setBtnsNames(buttonsData?.map((btn) => btn.name));

  },[buttonsData]);
  
  // Effect to set selected mailing in store and get emails for chosen mailing
  useEffect(() => {
    if (selectedParam === 'emails' && selectedValue) {
      const selectedMailing = buttonsData.find((item) => item.name === selectedValue).key_name;
      dispatch(setSelectedMailing(selectedMailing));
    }
  }, [selectedValue]);
  // Effect to set first button selected as default
  useEffect(() => {
    if (selectedParam === 'emails' && btnsNames?.length && !selectedMailing) {
      setSelectedValue(btnsNames[0]);
      dispatch(setSelectedMailing(buttonsData[0].key_name));
    }
  }, [btnsNames, buttonsData, selectedParam, selectedMailing]);

  return (
    <div className="adding-data-block__mailings">
      {btnsNames &&
        btnsNames.length > 0 &&
        btnsNames?.map((buttonName) => (
          <CustomButton
            className={`device_button device_button__mailings ${selectedValue === buttonName ? 'activeBtn' : ''}`}
            key={buttonName}
            width="94%"
            text={buttonName}
            colorText={'var(--sadr-text-color)'}
            onClick={() => setSelectedValue(buttonName)}
          />
        ))}
    </div>
  );
}

export default ButtonsPanelInBlock;
