/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../Table/Table';
import Modal from '../Modal/Modal';
import { defaultCellRender } from '../../utils/formatValueForHTML';
import CustomButtonEdit from '../CustomElements/CustomButtonEdit';
import CustomButton from '../CustomElements/CustomButton';
import DataModal from './DataModal';
import contentData from '../../content/PagesContent/adminDataPannel/admidDataPannelRu.json';
import { useRole } from '../../customHook/useRole';
import CustomButtonDelete from '../CustomElements/CustomButtonDelete';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import { deleteEmail, getData } from '../../redux/actions/adminChoice.action';
import HeaderForObject from '../CustomElements/HeaderForObject';
import ButtonsPanelInBlock from './ButtonsPanelInBlock';

function AddingDataBlock() {
  const isMaster = useRole('master');
  const [tableParametrs, setTableParametrs] = useState([]); //---- Creating of array of columns
  const [tableData, setTableData] = useState([]); //---- Creating of array of data for table
  const [modal, setModal] = useState(false); //---- State for modal window
  const [deleteModal, setDeleteModal] = useState(false); //---- State for confirmatioon of deleting modal window
  const [nameToDelete, setNameToDelete] = useState('');
  const [modalId, setModalId] = useState(); //---- Set id for editing parameter
  const [tableTitle, setTableTitle] = useState('');

  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState('');
  //---- Getting all data for administration page from Redux store
  const backendData = useSelector((state) => state.adminChoiceData);

  const openDataModal = (chosenId) => {
    setModal(true);
    setModalId(chosenId);
  };
  const closeDataModal = () => {
    setModal(false);
  };
  /////TO ACTIONS
  const handleDeleteRow = async () => {
    try {
      
      const result = await deleteEmail(modalId);
      if (result.status === 200) {
        setDeleteModal(false);
        dispatch(
          getData(`${backendData.selectedParam}/${backendData.data.mailings?.find((item) => item.selected).key_name}`)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  ///////////////////////////////
  const selectedParam = backendData?.selectedParam; //---- Selected parameter (pressed button)
  const headerData = useMemo(() => contentData[selectedParam], [selectedParam]); //---- Titles for table columns

  //---- Creating table with data to show for user
  useEffect(() => {
    if (backendData && backendData.data && backendData.selectedParam) {
      const selectedData = backendData.data[selectedParam];
      if (selectedData) {
        const newTableParametrs = headerData.header
          .map((item, index) => {
            if (headerData.accessor[index] === 'device') {
              return {
                Header: item,
                accessor: headerData.accessor[index],
                Cell: ({ value }) => (value ? 'Да' : 'Нет'),
              };
            } else if (!headerData.accessor[index].endsWith('_id')) {
              return {
                Header: item,
                accessor: headerData.accessor[index],
                Cell: ({ value }) => defaultCellRender(value),
              };
            }
            return null;
          })
          .filter(Boolean);
  
        if (!isMaster && selectedParam !== 'emails') {
          newTableParametrs.push({
            Header: 'Изменить',
            disableSorting: true,
            Cell: ({
              row: {
                original: { id },
              },
            }) => (
              <CustomButtonEdit
                onClick={() => {
                  openDataModal(id);
                }}
              />
            ),
          });          
        }else if (!isMaster && selectedParam === 'emails') {
          newTableParametrs.push({
            Header: 'Удалить',
            disableSorting: true, // Флаг для отключения сортировки
            Cell: ({
              row: {
                original: { id, email },
              },
            }) => (
              <div className="">
                <CustomButtonDelete
                  onClick={() => {
                    setNameToDelete(email);
                    setModalId(id);
                    setDeleteModal(true);
                  }}
                />
              </div>
            ),
          });
        }
        if (backendData?.data?.mailings?.find((item) => item?.selected)?.name) {
          setTableTitle(backendData?.data?.mailings?.find((item) => item?.selected)?.name);
        } 
        const newTableData = selectedData.map((item) => {
          const filteredItem = {};
          Object.entries(item).forEach(([key, value]) => {
            if (!key.endsWith('_id')) {
              filteredItem[key] = value;
            }
          });
          return filteredItem;
        });
  
        // Устанавливаем состояния только если данные изменились
        if (JSON.stringify(newTableParametrs) !== JSON.stringify(tableParametrs)) {
          setTableParametrs(newTableParametrs);
        }
        if (JSON.stringify(newTableData) !== JSON.stringify(tableData)) {
          setTableData(newTableData);
        }
      }
    }
  }, [backendData, selectedParam, headerData, isMaster, tableParametrs, tableData]);

  const columnEmployees = [
    {
      Header: 'ID',
      accessor: 'id',
      Cell: ({ value }) => defaultCellRender(value),
    },
    {
      Header: 'Фамилия',
      accessor: 'surname',
      Cell: ({ value }) => defaultCellRender(value),
    },

    {
      Header: 'Имя',
      accessor: 'name',
      Cell: ({ value }) => defaultCellRender(value),
    },
    {
      Header: 'Отчество',
      accessor: 'patronymic',
      Cell: ({ value }) => defaultCellRender(value),
    },
    {
      Header: 'Контакты',
      accessor: 'contact',
      Cell: ({ value }) => defaultCellRender(value),
    },
    {
      Header: 'Должность',
      accessor: 'position',
      Cell: ({ value }) => defaultCellRender(value),
    },
    {
      Header: 'Полное название должности',
      accessor: 'position_full_name',
      Cell: ({ value }) => defaultCellRender(value),
    },
    {
      Header: 'Дирекция',
      accessor: 'directorate_name',
      Cell: ({ value }) => defaultCellRender(value),
    },
    {
      Header: 'Подразделение',
      accessor: 'department_name',
      Cell: ({ value }) => defaultCellRender(value),
    },
    !isMaster && {
      Header: 'Изменить',
      disableSorting: true, // Флаг для отключения сортировки
      Cell: ({
        row: {
          original: { id },
        },
      }) => (
        <div className="">
          <CustomButtonEdit
            onClick={() => {
              openDataModal(id);
            }}
          />
        </div>
      ),
    },
  ].filter(Boolean);

  return (
    <section className="adding-data-block">
      <HeaderForObject
        searchInput={searchInput}
        onFilterChange={(e) => {
          setSearchInput(e?.target?.value);
        }}
        onFilterSubmit={(e) => {
          setSearchInput(e?.target?.value);
        }}
      />
      <div className="adding-data-block__content">
        {backendData.selectedParam === 'emails' && <ButtonsPanelInBlock selectedParam={backendData.selectedParam} />}
        <div className="adding-data-block__table">
          {/* Draw table with data */}
          <Table
            className="adding-data-block__inner-table"
            data={tableData}
            columns={backendData.selectedParam === 'employees' ? columnEmployees : tableParametrs}
            searchInput={searchInput}
            viewHeader={tableTitle && selectedParam === 'emails' ? tableTitle : null}
          />

          {/* Button for adding new data (label from json, according to selected parameter) */}

          <div className={`adding-data-block__button ${isMaster ? 'padding-10' : ''}`}>
            {!isMaster && (
              <CustomButton
                text={`+ ${contentData.addLabels[backendData.selectedParam]}`}
                onClick={() => {
                  openDataModal();
                }}
              />
            )}
          </div>

          {/* Modal window for editing/adding data */}
          {modal && (
            <Modal isOpen={modal} onClose={closeDataModal} width="70%" height="90%">
              <DataModal changingId={modalId} setModal={setModal} />
            </Modal>
          )}
          {/* Modal window for deleting data */}
          {deleteModal && (
            <Modal isOpen={deleteModal} onClose={() => setDeleteModal(false)} width="70%" height="90%">
              <ConfirmationPage
                // creating result unswer according to the mode and checked parameter. All text from json
                confirmText={`${headerData.confirmDelete} ${nameToDelete}`}
                onConfirm={handleDeleteRow}
                onCancel={() => setDeleteModal(false)}
                confirmButtonText={contentData.confirmButtonText}
                cancelButtonText={contentData.cancelButtonText}
              />
            </Modal>
          )}
        </div>
      </div>
    </section>
  );
}
export default AddingDataBlock;

