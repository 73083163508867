import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../../styles/FilterForArchive.css';
import Selector from '../CustomElements/Selector';
import Input from '../CustomElements/Input';
import Accordeon from '../CustomElements/Accordeon';

import { useFilterControl } from '../../customHook/useFilterControl';
import useOptionsLoader from '../../customHook/useOptionsLoader';

import { handleUniversalChange } from '../../utils/formUtils';

import { initArchiveFromServer } from '../../redux/actions/archive.action';
import {
  initDepartmenOptionsFromServer,
  initOptionsDirectoratesFromServer,
  initOptionsSectionFromServer,
} from '../../redux/actions/options.action';
import DateRangePicker from '../CustomElements/DateRangePicker';

function FilterForArchive({ initialFormData, formData, setFormData }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // получаем опции из стора
  const optionsForSelect = useSelector((state) => state.options);

  // хук для сброса филльтров и флага для отображения кнопки сброса
  const { resetFilters, showBtnReset } = useFilterControl(initialFormData, formData, setFormData);

  const periodMessage = 'Выберите период';
  const [message, setMessage] = useState(periodMessage);

  const messageForSelectDirectorates = 'Выберите дорогу';
  const directoratesControl = useOptionsLoader(
    formData,
    setFormData,
    ['directorateId', 'departmentId'],
    'roadId',
    initOptionsDirectoratesFromServer,
    messageForSelectDirectorates
  );

  //хук для запроса опций для подразделений и контроля селекта
  const messageForSelectDepartments = 'Выберите дирекцию';
  const departmentsControl = useOptionsLoader(
    formData,
    setFormData,
    ['departmentId'],
    'directorateId',
    initDepartmenOptionsFromServer,
    messageForSelectDepartments
  );

  useEffect(() => {
    const condition =
      ((formData?.brigadeId?.length === 6 && formData?.deviceId?.length === 6) ||
        (formData?.brigadeId?.length === 6 && formData?.deviceId?.length === 0) ||
        (formData?.brigadeId?.length === 0 && formData?.deviceId?.length === 6) ||
        (formData?.brigadeId?.length === 0 && formData?.deviceId?.length === 0)) &&
      formData?.start &&
      formData?.finish;
    const noFilters = Object.keys(formData).findIndex((key) => formData[key]) < 0;
    if (condition || noFilters) {
      dispatch(initArchiveFromServer(formData));
    }
    setMessage(formData?.start && formData?.finish ? null : periodMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  //хук для запроса опций для станций и контроля селекта
  const messageForSelectStation = 'Выберите дорогу';
  const stationControl = useOptionsLoader(
    formData,
    setFormData,
    ['stationId'],
    'roadId',
    initOptionsSectionFromServer,
    messageForSelectStation
  );

  return (
    <Accordeon header="АРХИВ" hideAbove={1650} qParamName={'docs_acc'} formData={formData} resetFilters={resetFilters}>
      <button
        type="button"
        className={`accordeon__reset-filters ${showBtnReset && 'accordeon__reset-filters_visible'}`}
        onClick={() => {
          resetFilters(false);
        }}
      >
        <span className="accordeon__reset-filters_text">Сбросить все фильтры</span>
        <img className="accordeon__reset-filters_icon" src="/image/close.svg" alt=""></img>
      </button>

      <form className="accordeon__form" id="" method="POST" action="#" autoComplete="off">
        <div className="accordeon__select-container" id="">
          <Input
            placeholder="Введите ID ИУР"
            onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 6)}
            value={formData?.brigadeId}
            name="brigadeId"
            // type="number"
            label="ID ИУР"
          />
          <Input
            placeholder="Введите ID устройства"
            onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 6)}
            value={formData?.deviceId}
            label="Устройство"
            name="deviceId"
            // type="number"
            maxLength={6}
          />
          <Selector
            options={optionsForSelect.roads}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'roadId', selectedOption, 'select');
            }}
            placeholder="Выберите дорогу"
            isMulti={false}
            label="Дорога"
            value={formData.roadId ? optionsForSelect?.roads?.find((option) => option.value === formData.roadId) : null}
            name="roadId"
          />
          <Selector
            column="right"
            options={optionsForSelect?.directorates}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'directorateId', selectedOption, 'select');
            }}
            placeholder="Выберите дирекцию"
            label="Дирекция"
            value={
              formData.directorateId
                ? optionsForSelect?.directorates?.find((option) => option.value === formData.directorateId)
                : null
            }
            isMulti={false}
            name="directorateId"
            isDisabled={directoratesControl?.isSelectorDisabled}
            message={directoratesControl?.message}
            customClick={directoratesControl?.onSelectorChange}
          />
          <Selector
            options={optionsForSelect.departments}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'departmentId', selectedOption, 'select');
            }}
            placeholder="Выберите подразделение"
            isMulti={false}
            label="Подразделение"
            value={
              formData.departmentId
                ? optionsForSelect?.departments?.find((option) => option.value === formData.departmentId)
                : null
            }
            name="departmentId"
            isDisabled={departmentsControl?.isSelectorDisabled}
            message={departmentsControl?.message}
            customClick={departmentsControl?.onSelectorChange}
          />
          <Selector
            options={optionsForSelect?.stations}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'stationId', selectedOption, 'select');
            }}
            value={
              formData?.stationId
                ? optionsForSelect.stations?.find((option) => option.value === formData.stationId)
                : null
            }
            isMulti={false}
            placeholder="Выберите станцию/перегон"
            name="stationId"
            label="Станция/перегон"
            isDisabled={stationControl?.isSelectorDisabled}
            message={stationControl?.message}
            customClick={stationControl?.onSelectorChange}
          />
          <DateRangePicker
            label="Период*"
            formData={formData}
            setFormData={setFormData}
            startDateField="start" // имя поля для даты начала, с дефолтным значением
            endDateField="finish" // имя поля для даты окончания, с дефолтным значением
            placeholder="Выберите период"
            name="date_period"
            message={message}
            maxDate={new Date()}
          />
        </div>
      </form>
    </Accordeon>
  );
}

export default FilterForArchive;
