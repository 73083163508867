/* eslint-disable eqeqeq */
import {
  ADD_INFOCARDS,
  ADD_RAIL_CHAINS,
  DELETE_RAIL_CHAINS,
  EDIT_INFOCARD,
  EDIT_RAIL_CHAINS,
  INIT_INFOCARDS,
  INIT_RAIL_CHAINS,
  INIT_ADJACENCY,
  DELETE_ADJACENCY,
  INIT_SEMAPHORES,
  DELETE_SEMAPHORE,
  ADD_SEMAPHORE,
  EDIT_SEMAPHORE,
  DELETE_INFOCARDS,
  ADD_ADJACENCY,
  SET_ADJACENCY,
  CLEAR_ADJACENCY_STATE,
  CLEAR_RAIL_CHAINS_STATE,
  CLEAR_SEMAPHORES_STATE,
  EDIT_MODE,
} from '../type';

export default function infocardReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case INIT_INFOCARDS:
      return { ...state, items: payload };

    case ADD_INFOCARDS:
      return { ...state, items: [...state.items, ...payload] };

    case EDIT_INFOCARD: {
      const { items } = state;

      for (let [station, infocard] of Object.entries(payload)) {
        let index = items.findIndex((x) => x.station == station);
        if (index >= 0) {
          items.splice(index, 1, infocard);
        }
      }

      return {
        ...state,
        items: [...items],
      };
    }

    case DELETE_INFOCARDS: {
      const items = state.items.filter((x) => !payload.some((key) => key == x.station));
      return {
        ...state,
        items,
      };
    }

    case EDIT_MODE: {
      const items = [...state.items]; // Создаём копию массива
      const index = items.findIndex((item) => item.station === payload.id);
    
      if (index !== -1) {
        items[index] = { ...items[index], is_disabled: payload.is_disabled }; // Обновляем только нужный объект
      }
    
      return {
        ...state,
        items,
      };
    }


    case INIT_RAIL_CHAINS:
      return { ...state, rail_chains: payload };

    case EDIT_RAIL_CHAINS: {
      const { items } = payload;
      const { rail_chains } = state;

      for (const updatedRail of items) {
        const index = rail_chains.findIndex((rail) => rail.id == updatedRail.id);
        if (index >= 0) {
          rail_chains.splice(index, 1, updatedRail);
        }
      }

      return {
        ...state,
        rail_chains: [...rail_chains],
      };
    }

    case DELETE_RAIL_CHAINS: {
      const { items } = payload;
      let { rail_chains } = state;
      rail_chains = rail_chains.filter((x) => !items.some((key) => key == x.id));
      return {
        ...state,
        rail_chains,
      };
    }

    case ADD_RAIL_CHAINS: {
      return {
        ...state,
        rail_chains: [...state.rail_chains, payload.rail],
      };
    }

    case CLEAR_RAIL_CHAINS_STATE:
      return { ...state, rail_chains: payload };

    case INIT_ADJACENCY:
      return {
        ...state,
        adjacency: payload,
      };

    case ADD_ADJACENCY: {
      return {
        ...state,
        adjacency: [...state.adjacency, ...payload],
      };
    }

    case DELETE_ADJACENCY: {
      const adjacencyId = payload;
      const updatedAdjacencys = state.adjacency.map((adjacency) => {
        if (adjacency.rail_chain === adjacencyId) {
          return {
            ...adjacency,
            adjacency: null, // Замена вложенного массива на null
          };
        }
        return adjacency; // Возвращаем объект без изменений, если не совпадает
      });

      return {
        ...state,
        adjacency: updatedAdjacencys,
      };
    }

    case SET_ADJACENCY: {
      const updatedAdjacency = state.adjacency.map((item) => {
        return item.rail_chain === payload.rail_chain ? payload : item;
      });
      return { ...state, adjacency: updatedAdjacency };
    }

    case CLEAR_ADJACENCY_STATE:
      return {
        ...state,
        adjacency: payload,
      };

    case INIT_SEMAPHORES: {
      return {
        ...state,
        semaphores: payload,
      };
    }

    case DELETE_SEMAPHORE: {
      return {
        ...state,
        semaphores: state.semaphores.filter((semaphore) => semaphore.semaphore_id !== payload),
      };
    }

    case ADD_SEMAPHORE: {
      return {
        ...state,
        semaphores: [payload, ...state.semaphores],
      };
    }

    case EDIT_SEMAPHORE: {
      const semaphores = state.semaphores.map((semaphore) =>
        semaphore?.semaphore_id === payload?.semaphore_id ? payload : semaphore
      );

      return {
        ...state,
        semaphores,
      };
    }

    case CLEAR_SEMAPHORES_STATE: {
      return {
        ...state,
        semaphores: payload,
      };
    }

    default:
      return state;
  }
}
